import { php } from './../endpoints.js';

const documents = {
    filterURL: '/documents/get-extracted-data',
    async getExternalData(data) {
        return php.get("/documents/get-extracted-data", {params: data}).then((res) => res.data);
    },
    async getDetails(sourceDataItemId) {
        return php.get(`/documents/${sourceDataItemId}/details`).then((res) => res.data);
    },
    async setToReprocess(sourceDataItemId) {
        return php.put(`/documents/${sourceDataItemId}/set-to-reprocess`).then((res) => res.data);
    },
}

export default documents;
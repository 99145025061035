<template>
    <div class="spinner" :class="{'top-right': isTopRight}" :id="'spinner-'+uniqueId" v-show="isLoading" :style="isCenterToPage ? 'position: fixed' : ''">
        <span><i class="fas fa-cog fa-spin fa-5x"></i></span>
        <h5 class="spinner-label" v-show="isLoading && loadingMsg">{{loadingMsg}}</h5>
    </div>
</template>

<script>
import _ from 'lodash';
export default {
	props: {
		isLoading: {
			type: Boolean,
			required: false,
            default: false
		},
        isCenterToPage: {
            type: Boolean,
            default: false,
            required: false,
        },
        isTopRight: {
            type: Boolean,
            default: false,
            required: false,
        },
        loadingMsg: {
            type: String,
            default: null,
            required: false,
        }
	},
    data(){
        return {
            uniqueId: _.uniqueId(),
        }
    },
    mounted(){
        if(this.isLoading){
             this.renderSpinner();
        }
    },
    methods:{
        renderSpinner(){            
            var element = document.getElementById('spinner-'+this.uniqueId);
             if(element){
                if(this.isLoading){
                    element.parentNode.classList.add('spinner-disable-block');
                }else{
                    element.parentNode.classList.remove('spinner-disable-block');
                }
             }
        }
    },
    watch:{
        isLoading(){
           this.renderSpinner()
        }
    },
    computed:{
        classList(){
            let classList='';
            if(this.isTopRight){
                let classList='spinner--top-right';
            }
            return classList;
        }
    }
}
</script>

<style lang="scss">

.spinner-disable-block
{
     .spinner ~ div, .spinner ~ a, .spinner ~ button{
        pointer-events: none;
        opacity: 0.4;
        position: relative;
     }

}

.spinner.top-right{
    position: absolute;
    display: block;
    padding-right: 2rem;
    top: 30%;
    right: 2rem;
    z-index: 99999;
    left: 0;
    text-align: end;
}

.spinner{
    position: absolute;
    display: block;
    width: 100%;
    top: 50%;
    z-index: 99999;
    text-align: center;
    left: 0;
    
    .fa-spin{
      color: $primary-btn-color;
    }
}

</style>
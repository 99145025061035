import Cookies from "js-cookie";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL_PHP;
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.transformRequest = (data, headers) => {
	headers["Authorization"] = Cookies.get("ORDINGESTFrontendSessionKey");
	return data;
}
export const php = axios.create({
  baseURL: process.env.BASE_URL_PHP,
  headers: {
		accept: "application/json, multipart/form-data",
		'Content-Type': "application/json"
  },
  transformRequest: (data, headers) => {
    headers["Authorization"] = Cookies.get("ORDINGESTFrontendSessionKey");
    return JSON.stringify(data);
  }
});


export const createPhpCrud = (endpoint) => ({
  async get(data = null) {
    const res = await php.get(`${endpoint}`, data);
    return res.data;
  },
  async getById(id, includeDependencies = false) {
    const res = await php.get(`${endpoint}/${id}?includeDependencies=${includeDependencies}`);
    return res.data;
  },
  async update(id, data) {
    const res = await php.put(`${endpoint}/${id}`, data);
    return res.data;
  },
  async create(data) {
    const res = await php.post(`${endpoint}`, data);
    return res.data;
  },
  async delete(id) {
    const res = await php.delete(`${endpoint}/${id}`);
    return res.data;
  }
});

//Import all modules -> start
import auth from './modules/auth';
import documents from './modules/documents';
import readers from './modules/readers';
import dashboard from './modules/dashboard';
import integrations from './modules/integrations';
import users from './modules/users';
import messages from './modules/messages';
import apiKeys from './modules/apiKeys';
//Import all modules -> end

export const body = {
   ...auth,
   documents: { ...documents },
   readers: { ...readers },
   dashboard: {...dashboard},
   integrations: {...integrations},
   users: {...users},
   messages: {...messages},
   apiKeys: {...apiKeys}
};
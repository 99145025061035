import { php } from './../endpoints.js';

const users = {
    async get() {
        return php.get("/user").then((res) => res.data);
    },
    async getById(id) {
        return php.get(`/user/${id}`).then((res) => res.data);
    },
    async update(id, data) {
        return php.put(`/user/${id}`, data).then((res) => res.data);
    },
    async create(data) {
        return php.post(`/user`, data).then((res) => res.data);
    },
    async delete(id) {
        return php.delete(`/user/${id}`).then((res) => res.data);
    }
}

export default users;
<template>
<div>
	<div class="dynamic-type-input" v-if="type == 'select'">
		<InputSelect
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:data="data"
			:value="modelValue"
			:placeholder="placeholder"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'checkbox'">
			<InputCheckbox
				class="mas-mr-2"
				:disabled="disabled"
				:name="name"
				:modelValue="modelValue"
				@update:modelValue="(val) => emitValues(val)"
				/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'textarea'">
		<InputTextArea
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
			:rows="rows"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'number'">
		<InputNumber
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
			:onkeypress=" preventDecimal ? 'return event.charCode >= 48 && event.charCode <= 57' : ''"
		/>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'text'">
		<InputText
			:fullWidth="fullWidth"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
		/>
		<template v-if="inputIcon">
			<span class="input-icon" @click="$emit('inputIconClicked')">
				<i :class="inputIcon"></i>
			</span>
		</template>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'virtualselect'">
		<InputVirtualSelect 
		  	:fullWidth="fullWidth"
			:hideInput="hideInput"
			@input="(val) => emitValues(val)"
			:value="modelValue"
			:placeholder="placeholder"
			:data="data"
			:isFilter="isFilter"
			:disabled="disabled"
		/>
		<template v-if="showCustomIcon">
			<span @click.prevent="$emit('iconClicked', true)" class="custom-icon">
				<font-awesome-icon :icon="showCustomIcon" />
			</span>
		</template>
	</div>
	<div class="dynamic-type-input" v-else-if="type == 'multiselect'">
		<InputMultiSelect 
			:fullWidth="fullWidth"
			@updateValue=" (values) => emitValues(values)"
			:disabled="disabled"
			:value="modelValue"
			:placeholder="placeholder"
			:data="data"
			:selected="selected"
			:isFilter="isFilter"
			:enableSearchAndAdd="enableSearchAndAdd"
			:searchAction="searchAction"
		/>
	</div>
	<div class="dynamic-type-input" v-else>
		<input
			class="mas-input"
			:class="fullWidth ? 'full-width' : ''"
			@input="$emit('update:modelValue', $event.target.value)"
			:value="modelValue"
			:type="type"
			:disabled="disabled"
			:min="minValue"
		/>
	</div>
</div>
</template>

<script>
import { props } from './common';
import { ref } from 'vue';
export default {
	props: [
		...props,
		'type',
		'hideInput',
		'fullWidth',
		'preventDecimal',
		'rows',
		'min',
		'name',
		'showCustomIcon'
	],
	setup(props, {emit}){
		const validationMessage = ref('')
		const minValue = ref(props.min)

		const emitValues = (values) => {
			emit('update:modelValue', values)
			emit('input', values)
		}

		return {
			validationMessage,
			minValue,
			emitValues
		}
	}
}
</script>

<style lang="scss">
.dynamic-type-input{
	position: relative;
	input:not([type="checkbox"]), textarea{
		border: 1px solid #DAE8FC;
		border-radius: 6px;
		width: 100%;
		padding-left: 13px;
		padding-right: 13px;
		height: 44px;
	}
	textarea{
		padding-top: 13px;
		height: unset ;

	}

	input:focus, textarea:focus {
		outline: none;
	}

	.custom-icon{
		position: absolute;
		top: -30px;
		right: 0;
		font-size: 20px;
    	cursor: pointer;
		color: $primary-btn-color;

	}
}
</style>
<template>
		<GlobSpinner :isLoading="isLoading"/>
		<GlobRow class="mt-4">
			<GlobCol :medium="12">
				<label>
					{{$t('api_key.fields.name')}}*
					<InputDynamic
					:fullWidth="true"
					:type="'text'"
					:placeholder="$t('api_key.fields.name')"
					v-model="apiKey.Name"
					/>
				</label>
			</GlobCol>

			<GlobCol :medium="12" v-if="apiKey && apiKey.Id" class="mt-4">
				<label>
					{{$t('api_key.fields.key')}}
					<InputDynamic
					:fullWidth="true"
					:disabled="true"
					:type="'text'"
					:placeholder="$t('api_key.fields.name')"
					v-model="apiKey.ApiToken"
					/>
				</label>
			</GlobCol>


			<GlobCol :medium="12" class="mt-4">
				<p class="text-danger" v-if="errorMessage">{{errorMessage}}</p>
				<button @click="save" class="button button--primary mt-4 mr-3" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
				<button @click="$emit('close')" class="button button--cancel mt-4 mr-3">{{$t('general.button.cancel')}}</button>
				<button @click="$emit('showDeleteConfirmModal')" class="button button--delete mt-4" v-if="apiKey.Id">{{$t('general.button.delete')}}</button>
			</GlobCol>
		</GlobRow>
</template>

<script>
import {onMounted, reactive, ref, computed } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";

export default {
  props: ['selectedItem'],
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.apiKeys);
	const api = useApi();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const apiKey = reactive({
			Id: "",
			Name: ""
	});


	onMounted(()=> {
		if(props.selectedItem){
			Object.assign(apiKey, props.selectedItem)
		}
	})

    const save = () => {

		isLoading.value = true;
		if(apiKey.Id){
			endpoint.value.update(apiKey.Id, apiKey).then((res) => {
				isLoading.value = false;
				notifications.push({
					title: tm('api_key')['update_success'],
					msg: tm('api_key')['update_success']
				}, 'success', false, false)
					emit('saved')
					emit('close')
			});

		}else{
			endpoint.value.create(apiKey).then((res) => {
				isLoading.value = false;
				notifications.push({
					title: tm('api_key')['create_success'],
					msg: tm('api_key')['create_success']
				}, 'success', false, false)
					emit('saved')
					emit('close')
			});
		}
		
    };

	const isSaveButtonDisabled = computed(() => {
		return !apiKey.Name ? true : false
	})


    return {
      save,
	  errorMessage,
	  apiKey,
	  isLoading,
	  isSaveButtonDisabled,
    };
  },
};
</script>

<style lang="scss">
</style>

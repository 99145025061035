import Home from "@/views/Home.vue";
import Document from "@/views/modules/documents/Index.vue";

const menuItems = [
    {
        name: "Home",
        path: "/",
        translation: "menu_items.dashboard",
        component: Home
    },
    {
        name: "Documents",
        path: "/documents",
        translation: 'menu_items.documents',
        component: Document
    }
]

export default menuItems;
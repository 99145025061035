<template>
  <thead class="table-head">
    <tr>
			<th
        v-for="item in data.head"
				@click="sortByKey(item.Alias)"
        :key="generateId(item.Alias)"
      >
        {{ item.Value }}
				<i v-if="item.Alias == sortedBy" :class="`fas fa-arrow-${descending ? 'down' : 'up'} down`"></i>
      </th>
			<th v-if="actions"></th>
    </tr>
  </thead>
</template>

<script>
import { generateId } from "@/services/utils";
import { ref } from 'vue';
export default {
	props: ['data', 'actions'],
	emits: ['sorted'],
	setup(props, { emit }) {
		const sortedBy = ref('');
		const descending = ref(false)
		const sortByKey = (key) => {
			sortedBy.value = key;
			if (key !== sortedBy.value) {
				descending.value = false;
			} else {
				descending.value = !descending.value;
			}
			emit('sorted', {key, descending})
		}
		return {
			generateId,
			sortedBy,
			sortByKey,
			descending
		}
	}
}
</script>

<style lang="scss" scoped>
.table-head{
	background-color: #ECF3FD;
	height: 39px;
	border: 1px solid $blue-shade;
	th{
		font-size: 16px;
		color: $dark-blue-shade;
		font-weight: 400;
		padding-left: 18px;
	}
}
</style>

<template>
   <div>
        <h2 class="page-title">{{ $t('documents.extracted_data.heading') }}</h2>
        <GlobRow>
            <GlobCol :medium="12">
                <GlobFilter
						@apply="(e) => loadItems(e)"
						:inputMinWidth="'250px'"
						:enable="false"
						:multiselect="true"
						:endpoint="filterURL"
						>
				</GlobFilter>
            </GlobCol>
        </GlobRow>
        
        <!-- Table listing start -->
        <GlobXpanel class="mt-5">
            <GlobSpinner :isLoading="isLoading"></GlobSpinner>
            <GlobRow>
                <GlobCol :medium="4" class="d-flex center-items-horizontal">
                    <label class="mr-4">{{ $t('documents.extracted_data.action') }}</label>
                    <InputDynamic  
                            :type="'select'"
                            :placeholder="'Select'"
                            :data="[{
                                name: 'Reprocess',
                                value: '1',
                            }
                            ]"
                        />
                </GlobCol>
            </GlobRow>
             <GlobRow>
                <GlobCol :medium="12" class="mt-3">
                    <GlobTable
                    class="mt-5"
                    :enableEditing="true"
                    :maxHeight="75"
                    :data="{
                        head: tableHead,
                        body: tableBody

                    }"
                    :fields="[
                        {
                            key: 'checkbox', type: 'checkbox',
                        }
                    ]"
                    :itemRenderFunctions="renderFunctions"
                    :actions = "actions"
                    :enablePagination="true"
                    :paginationCurrentPage="paginationCurrentPage"
                    :paginationLastPage="paginationLastPage"
                    @jumpToPage = "(pageNumber) =>jumpToPage(pageNumber)"
                />
                </GlobCol>
             </GlobRow>
            
        </GlobXpanel>
        <!-- Table listing end -->

           <!-- View document start -->
        <GlobModal
            :width="'90vw'"
            :height="'90vh'"
            :maxContentHeight="'90vh'"
            @close="seletedItem = null"
            :title="seletedItem.name ? seletedItem.name : ''"
            :show="seletedItem && !selectedItemIdToReprocess ? true : false"
            v-if="seletedItem"
        >    
        <GlobSpinner :isLoading="isLoadingViewModal"></GlobSpinner>
                <div class="d-flex">

                    <GlobPdfView :fileURL="seletedItem.url" v-if="seletedItem.url" class="proccess-document"></GlobPdfView>
                    <div class="processed-data">
                        <button    
                            class="button button--primary float-right" 
                            @click="selectedItemIdToReprocess = seletedItem.id"
                            v-if="seletedItem.processed">{{ $t('documents.extracted_data.reprocess') }}</button>
                        <label class="d-flex mb-2">{{ $t('documents.extracted_data.read') }}: <p class="mt-0 mb-0 ml-2"><b>{{ formatDateTime(seletedItem.received_time) }}</b></p></label>
                        <label class="d-flex mb-2">{{ $t('documents.extracted_data.processed') }}: <p class="mt-0 mb-0 ml-2"><b>{{ formatDateTime(seletedItem.process_time) }}</b></p></label>
                        <label class="d-flex mb-2">{{ $t('documents.extracted_data.integrated') }}: <p class="mt-0 mb-0 ml-2"><b>{{ formatDateTime(seletedItem.integrated_time) }}</b></p></label>

                        <h3 class="mt-7 mb-2">{{ $t('documents.extracted_data.data') }}</h3>
                        <GlobJsonView :jsonData="seletedItem.processed_data" />
                    </div>
                </div>

                <!-- View document end -->
            
   </GlobModal>


    <!-- Set to reprocess start -->
    <GlobConsequence  
        :title="$t('documents.extracted_data.set_to_reprocess')"
        :content="$t('documents.extracted_data.set_to_reprocess_confirm')"
        :confirmButtonText="$t('general.yes')"
        :cancelButtonText="$t('general.no')"
        @close="selectedItemIdToReprocess = null"
        @delete="setToReprocess()"
        v-if="selectedItemIdToReprocess"
    />
    <!-- Set to reprocess stop -->
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { formatDateTime } from '@/helpers.js';
import { useNotifications } from "@/plugins/notifications";

export default {
    setup() {
        const { tm } = useI18n();
        const notifications = useNotifications();
        const seletedItem = ref(null);
        const isLoadingViewModal = ref(false);
        const selectedItemIdToReprocess = ref(null);
        const api = useApi();
        const endpoint = api.documents;
        const filterURL = computed(() => api.documents.filterURL);
        const isLoading = ref(false);
        const filters = ref({});
        const tableBody = ref([]);
        const tableHead = ref([
            {
                Alias: 'checkbox',
                Value: '',
            },
            {
                Alias: 'ReceivedDate',
                Value: computed(() => tm('documents')['extracted_data']['received_date'])
            },
            {
                Alias: 'Subject',
                Value: computed(() => tm('documents')['extracted_data']['received_date'])
            },
            {
                Alias: 'From',
                Value: computed(() => tm('documents')['extracted_data']['from'])
            },
            {
                Alias: 'Reader',
                Value: computed(() => tm('documents')['extracted_data']['reader'])
            },
            {
                Alias: 'Source',
                Value: computed(() => tm('documents')['extracted_data']['source'])
            },
            {
                Alias: 'FileName',
                Value: computed(() => tm('documents')['extracted_data']['file_name'])
            },
            {
                Alias: 'HasData',
                Value: computed(() => tm('documents')['extracted_data']['has_data'])
            },
            {
                Alias: 'IsProcessed',
                Value: computed(() => tm('documents')['extracted_data']['is_processed'])
            },
            {
                Alias: 'ErrorExists',
                Value: computed(() => tm('documents')['extracted_data']['has_error'])
            }
        ]);
        const renderFunctions = computed(() => {
            return {
                HasData(obj) {
                    if (obj.HasData) {
                        return '<span class="check-icon"><i class="fa-solid fa-check"></i></span>';
                    }
                    return;
                },
                IsProcessed(obj) {
                    if (obj.IsProcessed) {
                        return '<span class="check-icon"><i class="fa-solid fa-check"></i></span>';
                    }
                    return;
                },
                ErrorExists(obj) {
                    if (obj.ErrorExists > 0) {
                        return '<span class="warning-icon"><i class="fa-solid fa-triangle-exclamation"></i></span>';
                    }
                    return;
                }
            };
        });
        const actions = computed(() => {
            return [
                {
                    name: tm('documents')['extracted_data']['view'],
                    type: "primary",
                    action: (obj) => {
                        isLoadingViewModal.value = true;
                        seletedItem.value = {};
                        endpoint.getDetails(obj.SourceDataItemId).then((res) => {
                            seletedItem.value = res;
                            isLoadingViewModal.value = false;
                        })
                    }
                },
                {
                    name: tm('documents')['extracted_data']['reprocess'],
                    type: "secondary",
                    if: (obj) => obj.IsProcessed,
                    action: (obj) => {
                        console.log('obj', obj)
                        selectedItemIdToReprocess.value = obj.SourceDataItemId;
                    }
                },
            ];
        });
        onMounted(() => {
            loadItems();
        });

        const paginationCurrentPage = ref(0);
        const paginationLastPage = ref(0);
        const pageNumber = ref(0);

        const jumpToPage = (num) => {
            pageNumber.value = num - 1;
            loadItems();
        }

        const loadItems = (e) => {
            isLoading.value = true;
            let body = { filters: {} };
            if (e && e.body) {
                filters.value = e.filters;
            }else{
                body.page = pageNumber.value ? pageNumber.value : 0;
            }

            body.filters = filters.value;
            body.limit = 20;
            

            endpoint.getExternalData({ ...body }).then((res) => {

                tableBody.value = res.data;
                paginationCurrentPage.value = res.currentPage + 1;
		        paginationLastPage.value = res.pages;
                isLoading.value = false;
            });
        };

        const setToReprocess = () => {
            isLoading.value = true;
            const id = selectedItemIdToReprocess.value;
            selectedItemIdToReprocess.value = null;
            endpoint.setToReprocess(id).then((res) => {

                const index = tableBody.value.findIndex( i => i.SourceDataItemId == id);
                tableBody.value[index].IsProcessed = false;

                if(seletedItem.value && seletedItem.value.id){
                    seletedItem.value.processed = false;
                }

                notifications.push({
                    msg: tm('documents')['extracted_data']['set_to_reprocees_successfully']
               }, 'success', false, false)
                isLoading.value = false;
            });
        }

        return {
            loadItems,
            renderFunctions,
            actions,
            tableBody,
            tableHead,
            isLoading,
            filterURL,
            isLoadingViewModal,
            seletedItem,
            formatDateTime,
            paginationCurrentPage,
            paginationLastPage,
            jumpToPage,
            selectedItemIdToReprocess,
            setToReprocess
        };
    },
}
</script>

<style lang="scss">
div.processed-data{
    width: 40%;
    padding-left: 48px;
    text-align: start;
    min-width: 450px;
}


.proccess-document{
    width: 60%;
}
</style>
<template>
  <template v-if="localData.length">
    <tbody v-if="enableEditing" class="table-body">
      <tr v-for="item in localData" :key="generateId(item.Id)" class="table-body__row">
        <!-- Item -->
        <td v-for="entry in data.head" :key="generateId(entry.Alias)">
          <InputDynamic
            style="padding: .25rem .5rem;"
            @update:modelValue="(value) => $emit('change-item', { item: item, alias: entry.Alias, value: value})"
            v-if="getEditableField(entry)"
            v-model="item[entry.Alias]"
            :type="getEditableField(entry).type"/>
          <span v-else v-html="renderer.render(item, entry.Alias)"></span>
        </td>
        <!-- Actions -->
        <td v-if="actions">
          <span v-for="action in actions" :key="generateId(action.name)">
            <span class="table-action" v-if="action.if ? (typeof action.if == 'function' ? action.if(item) : action.if) : true">
              <button
                class="button "
                @click="execAction(action, item, $event)"
                :title="action.description && action.description"
                :class="generateButtonClasslist(action, item)"
              >
                <i :class="action.icon" v-if="action.icon"></i>
                {{ action.name ? action.name : ''  }}
              </button>
            </span>
          </span>
        </td>
      </tr>
    </tbody>
    <tbody v-else class="table-body">
      <tr v-for="item in localData" :key="generateId(item.Id)" class="table-body__row" :class="generateTableRowStyle(item)">
        <!-- Item -->
        <td v-for="entry in data.head" 
          :key="generateId(entry.Alias)" 
          :style="{
              backgroundColor: renderer.getTableCellColor(entry, item, 'BgColor'), 
            }"
          v-html="renderer.render(item, entry.Alias)"
          ></td>
        <!-- Actions -->
        <td v-if="actions">
          <span v-for="action in filteredActions" :key="generateId(action.name)" class="table-action">
            <template v-if="action.if ? action.if(item) : true">
					<template v-if="action.icon">
					<span
				class="table-action"
				:title="action.description"
				@click="execAction(action, item, $event)"
				>
				<i :class="action.icon" 
					:style="action.color ? 'color:'+action.color : ''" 
					v-if="action.icon"></i>
					</span>
					</template>
					<template v-else>
				<button
				class="button "
				@click="execAction(action, item, $event)"
				:title="action.description && action.description"
				:class="generateButtonClasslist(action, item)"
				>
				{{ action.name }}
					</button>
					</template>
			</template>
            
          </span>
        </td>
      </tr>
    </tbody>
  </template>
  <GlobModal
    :width="10"
    :isLoaded="activeAction && activeAction.isLoaded"
    :show="activeAction && activeAction.isLoaded"
    @close="activeAction = null"
    title="Är du säker?"
    v-if="activeAction && activeAction.isLoaded"
  >
    <p
      style="margin: .5rem 0 0 0; text-align: center;"
      v-html="activeAction.action.requirements.consequence(activeAction.item)"
    ></p>
    <div style="display: flex; margin: 0 1rem;">
      <button
        class="button button--red button--margin"
        @click="doFinalAction()"
        :title="activeAction.action.description"
      >
        Ja
      </button>
      <button class="button button--orange button--margin" style="margin-left: auto;" @click="activeAction = null">
        Nej
      </button>
    </div>
  </GlobModal>
</template>

<script>
import { useRenderFunctions, useItemRenderer } from "@/components/globals/table/common";
import { generateId } from "@/services/utils";
import { reactive, ref, onMounted, computed, watchEffect } from "vue";
export default {
  emits: ["change-item"],
  props: ["data", "itemRenderFunctions", "enableRenderFunctions", "actions", "enableEditing", "fields", "sortingData", "rowStylingFunction"],
  setup(props) {
		const { sortingData } = reactive(props);
    const generateButtonClasslist = (action) => {
      const style = `button--${action.type}`;
      return style;
    };

    const renderFunctions = props.itemRenderFunctions || (props.enableRenderFunctions && useRenderFunctions());
    const renderer = useItemRenderer(renderFunctions);

    //Actions
    let visibleReqs = reactive([]);
    let activeAction = ref(null);
    const execAction = (action, item, e) => {
      e.stopPropagation();
      if (action.requirements) {
        activeAction.value = {
          isLoaded: true,
          action,
          item,
        };
      } else {
        action.action(item);
      }
    };
    const doFinalAction = () => {
      activeAction.value.action.action(activeAction.value.item);
      activeAction.value = null;
    };

    const getEditableField = (item) => {
      const field = props.fields.find((field) => field.key == item.Alias);
      if (field) {
        return field;
      } else {
        return false;
      }
    };

    const localData = computed(() => {
      if (props.sortingData.key) {
        return props.data.body
          .map((i) => Object.assign({}, i))
          .sort((a, b) => {
            if (a[props.sortingData.key] < b[props.sortingData.key]) {
              return props.sortingData.descending ? -1 : 1;
            }
            if (a[props.sortingData.key] > b[props.sortingData.key]) {
              return props.sortingData.descending ? 1 : -1;
            }
            return 0;
          });
      } else {
        return props.data.body.map((i) => Object.assign({}, i));
      }
    });

    const log = (val) => {
      console.log(val);
    };

    const filteredActions = computed(() => props.actions);

	const generateTableRowStyle = (item) => {
		if(props.rowStylingFunction){
			return props.rowStylingFunction(item);
		}
	}

    return {
      renderer,
      generateButtonClasslist,
      generateId,
      execAction,
      activeAction,
      doFinalAction,
      getEditableField,
      localData,
      log,
      filteredActions,
	  generateTableRowStyle
    };
  },
};
</script>

<style lang="scss">
.table-body td
{
  border: 1px solid $blue-shade;
  font-size: 16px;
  color: $dark-blue-shade;
  height: 54px;
  padding-left: 18px;

  .check-icon{
    color: #11B5B5;
    font-size: 22px;
  }

  .cross-icon{
    color: $red;
    font-size: 22px;
  }

  .warning-icon{
    color: $orange;
    font-size: 22px;
  }
}
  .table-body {


    tr:nth-child(odd) {
      background-color:  $white;
    }

    tr:nth-child(even) {
      background-color:  #FCFDFF;
    }

    span.table-action{
      margin-right: 9px;
    }
}

.empty-table-message{
      text-align: center;
      padding: 2rem;
      font-size: 1.5rem;
      opacity: .4;
  }

</style>

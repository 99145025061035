<template>
    <GlobModal
       :width="'1500px'"
       :minContentHeight="'430px'"
        :title="'Edit Sender'"
        @close="$emit('close')"
        :show="true && !selectedApprovalSenderToDelete"
    > 
    <GlobSpinner :isLoading="isLoading"></GlobSpinner>
    <GlobRow class="mt-4">
        <GlobCol :medium="4">
            <label>{{$t('reader_setup.source_section.customer')}}*
                <InputDynamic  
                    :type="'virtualselect'"
                    :placeholder="$t('reader_setup.source_section.customer')"
                    v-model="customer.CustomerId"
                    :data="customers"
                />
            </label>
        </GlobCol>
    </GlobRow>
    <GlobRow class="mt-4" v-if="customer.CustomerId">
        <GlobCol :medium="12">
            <label>{{$t('reader_setup.source_section.context')}}
                <InputDynamic  
                    :type="'textarea'"
                    :rows="27"
                    :placeholder="$t('reader_setup.source_section.context')"
                    v-model="customer.Context"
                />
            </label>
        </GlobCol>
    </GlobRow>
    <p class="hint" v-if="customer.CustomerId && !selectedSourceCustomer">{{ $t('reader_setup.source_section.save_customer_for_add_senders') }}</p>
    <GlobRow class="mt-8">
        <GlobCol>
            <button class="button button--primary filter-trigger mr-2" :disabled="!customer.CustomerId" @click="save()">{{  $t('general.button.save') }}</button>
            <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
        </GlobCol>
    </GlobRow>

    <!-- Approved Senders start -->
    <template v-if="selectedSourceCustomer">
        <div class="p-relative">
            <h3>{{ $t('reader_setup.source_section.approved_senders') }}</h3>
            <label class="create-new-item" @click="showApprovedSenderForm = true">{{ $t('reader_setup.source_section.add_sender') }}<font-awesome-icon icon="fa-regular fa-square-plus" /></label>
        </div>
        <GlobRow v-if="!showApprovedSenderForm">
            <GlobCol :medium="12">
                <GlobTable
                    :maxHeight="60"
                    :data="{
                        head: approvedSenderTableHead,
                        body: approvedSenderTableBody

                    }"
                    :actions = "approvedSenderTableActions"
                />
            </GlobCol>
        </GlobRow>
        <template v-if="showApprovedSenderForm">
            <GlobRow>
                <GlobCol :medium="6">
                    <label>{{$t('reader_setup.source_section.name')}}*
                        <InputDynamic  
                            :type="'text'"
                            :placeholder="$t('reader_setup.source_section.name')"
                            v-model="approvedCustomer.Name"
                        />
                    </label>
                </GlobCol>
                <GlobCol :medium="6">
                    <label>{{$t('reader_setup.source_section.email')}}*
                        <InputDynamic  
                            :type="'text'"
                            :placeholder="$t('reader_setup.source_section.email')"
                            v-model="approvedCustomer.Email"
                        />
                    </label>
                </GlobCol>
            </GlobRow>
            <GlobRow class="mt-8">
                <GlobCol>
                    <button class="button button--primary filter-trigger mr-2" :disabled="isSaveApprovedCustomerButtonDisabled" @click="saveApprovalCustomer()">{{  $t('general.button.save') }}</button>
                    <button class="button button--cancel" @click="showApprovedSenderForm = false">{{  $t('general.button.cancel') }}</button>
                </GlobCol>
            </GlobRow>
        </template>
        
    </template>
    <!-- Approved Senders end -->

    </GlobModal>

    <!-- Delete approval sender confirm start -->
    <GlobConsequence  
        :title="$t('reader_setup.source_section.delete_sender')"
        :content="$t('reader_setup.source_section.delete_sender_confirm')"
        @close="selectedApprovalSenderToDelete = false"
        @delete="deleteApprovalSender()"
        v-if="selectedApprovalSenderToDelete"
        />
    <!-- Delete approval sender confirm end -->
</template>

<script>
import { onMounted, computed, ref, reactive, watch } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

export default {
    props: ['readerId', 'readerSource'],
    setup(props, {emit}) {
        const { tm } = useI18n();
        const api = useApi();
        const notifications = useNotifications();
        const endpoint = api.readers;

        const isLoading = ref(false);

        const customer = reactive({
            'CustomerId': null,
            'Context': '',
        });

        const customers = ref([]);
        const readerSourceCustomers = ref([]);
        const selectedSourceCustomer = ref(null);

        onMounted(() => {
            loadData();
        })

        const loadData = () => {
            isLoading.value = true;
            endpoint.getReaderSourceCustomers(props.readerId, props.readerSource.ReaderSourceId).then((res) => {
                customers.value = res.customers.map((customer) => {
                    return {
                        name: customer.Customer,
                        value: customer.Id
                    }
                })

                readerSourceCustomers.value = res.data;

                isLoading.value = false;
            })
        }

        const showApprovedSenderForm = ref(false);
        const approvedCustomer = reactive({});
        const selectedApprovalSenderToDelete = ref(false);
        const approvedSenderTableBody = computed (() => selectedSourceCustomer.value ? selectedSourceCustomer.value.reader_source_approvals : []  );
        const approvedSenderTableHead = ref([
                {
                    Alias: 'Name',
                    Value: computed(() => tm('reader_setup')['source_section']['name'])
                },
                {
                    Alias: 'Email',
                    Value: computed(() => tm('reader_setup')['source_section']['email'])
                },
        ]);

        

        const isSaveButtonDisabled = computed(() => {
            return false
        });

        watch(() => customer.CustomerId, (newVal, oldVal) => {
            customer.Context = '';
            customer.ReaderSourceCustomerId = '';
            if(customer.CustomerId){
                selectedSourceCustomer.value = readerSourceCustomers.value.find(i=> i.CustomerId == customer.CustomerId)
                if(selectedSourceCustomer.value){
                    customer.Context = selectedSourceCustomer.value.Context;
                    customer.ReaderSourceCustomerId = selectedSourceCustomer.value.Id;
                }
            }else{
                selectedSourceCustomer.value = null;
            }
        });

        const save = () => {
            isLoading.value = true;
            endpoint.saveReaderSourceCustomers(props.readerId, props.readerSource.ReaderSourceId, customer).then((res) => {
                readerSourceCustomers.value = res.reader_source_customers;
                selectedSourceCustomer.value = readerSourceCustomers.value.find(i=> i.CustomerId == customer.CustomerId)
                isLoading.value = false;
            });
        }

        const saveApprovalCustomer = () => {
            isLoading.value = true;
            endpoint.saveApprovalCustomer(props.readerId, props.readerSource.ReaderSourceId, {
                ReaderSourceCustomerId :selectedSourceCustomer.value.Id,
                ...approvedCustomer
            }).then((res) => {  
                approvedCustomer.Name = '';
                approvedCustomer.Email = '';
                const index = readerSourceCustomers.value.findIndex(i=> i.Id == selectedSourceCustomer.value.Id);
                readerSourceCustomers.value[index].reader_source_approvals = res.reader_source_approvals;
                showApprovedSenderForm.value = false;
                isLoading.value = false;
            });
        }

        const isSaveApprovedCustomerButtonDisabled = computed(() => {
            if(!approvedCustomer.Name || !approvedCustomer.Email){
                return true;
            }

            return false;
        });
        
        const approvedSenderTableActions = computed(() => {
            return [
                {
                    icon: 'fa-solid fa-trash icon--danger',
                    action: (obj) => {
                        selectedApprovalSenderToDelete.value = obj.Id;
                    }
                }
            ];
        });

        const deleteApprovalSender = () => {
            const id = selectedApprovalSenderToDelete.value;
            selectedApprovalSenderToDelete.value = null;
            isLoading.value = true;
            endpoint.deleteApprovalCustomer(props.readerId, props.readerSource.ReaderSourceId, {
                ReaderSourceCustomerId :selectedSourceCustomer.value.Id,
                ApprovedSenderId : id,
            }).then((res) => {  

                const index = readerSourceCustomers.value.findIndex(i=> i.Id == selectedSourceCustomer.value.Id);
                readerSourceCustomers.value[index].reader_source_approvals = res.reader_source_approvals;
                showApprovedSenderForm.value = false;
                isLoading.value = false;
            });
        }
        


        return {
            isLoading,
            isSaveButtonDisabled,
            customer,
            customers,
            selectedSourceCustomer,
            save,
            approvedSenderTableHead,
            approvedSenderTableBody,
            showApprovedSenderForm,
            approvedCustomer,
            isSaveApprovedCustomerButtonDisabled,
            saveApprovalCustomer,
            approvedSenderTableActions,
            selectedApprovalSenderToDelete,
            deleteApprovalSender
        }
    }

}

</script>

<style scoped>
label.create-new-item {
    top: -11%;
}
</style>
<template>
    <div>
        <div class="p-relative">
            <h2 class="page-title">{{ $t('reader_setup.readers_setup') }}</h2>
            <label class="create-new-item" @click="showCreateModal = true">{{ $t('reader_setup.create_new_reader') }}<font-awesome-icon icon="fa-regular fa-square-plus" /></label>
        </div>

        <!-- Table listing start -->
        <GlobXpanel class="mt-5">
            <GlobSpinner :isLoading="isLoading"></GlobSpinner>
            <GlobRow>
                <GlobCol :medium="12" class="mt-3">
                    <GlobTable
                    class="mt-5"
                    :maxHeight="75"
                    :data="{
                        head: tableHead,
                        body: tableBody

                    }"
                    :itemRenderFunctions="renderFunctions"
                    :actions = "actions"
                />
                </GlobCol>
            </GlobRow>
        </GlobXpanel>
        <!-- Table listing end -->

        <CreateReader v-if="showCreateModal" 
            @close="showCreateModal = false"
            @created="showCreateModal = false, loadItems()"/>

        <EditReader v-if="selectedReaderId" 
            :readerId="selectedReaderId"
            @close="selectedReaderId = false"
            @updated="selectedReaderId = false, loadItems()"/>


        <!-- Delete reader confirm start -->
        <GlobConsequence  
            :title="$t('reader_setup.delete_reader')"
            :content="$t('reader_setup.delete_reader_confirm')"
            @close="selectedReaderIdForDelete = false"
            @delete="deleteReader()"
            v-if="selectedReaderIdForDelete"
        />
         <!-- Delete reader confirm stop -->

    </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

import CreateReader from './Create.vue';
import EditReader from './Edit.vue';

export default {
    components: {
        CreateReader,
        EditReader
    },
    setup() {
        const { tm } = useI18n();
        const notifications = useNotifications();
        const api = useApi();
        const endpoint = api.readers;

        const isLoading = ref(false);
        const showCreateModal = ref(false);
        const selectedReaderId = ref(false);
        const selectedReaderIdForDelete = ref(null);

        const actions = computed(() => {
            return [
                {
                    name: tm('general')['button']['open'],
                    type: "primary",
                    action: (obj) => {
                        selectedReaderId.value = obj.ReaderId;
                    }
                },
                {
                    name: tm('general')['button']['delete'],
                    type: "delete",
                    action: (obj) => {
                        selectedReaderIdForDelete.value = obj.ReaderId;
                    }
                }
            ];
        });

        const renderFunctions = computed(() => {
            return {
                Status(obj) {
                    if(obj.Status){
                        return tm('reader_setup')['active'];
                    }
                    return tm('reader_setup')['in_active'];
                }
            }
        })

        const tableBody = ref([]);
        const tableHead = ref([
                {
                    Alias: 'Reader',
                    Value: computed(() => tm('reader_setup')['reader'])
                },
                {
                    Alias: 'ReaderType',
                    Value: computed(() => tm('reader_setup')['type'])
                },
                {
                    Alias: 'Interpreter',
                    Value: computed(() => tm('reader_setup')['interpreter'])
                },
                {
                    Alias: 'SourceType',
                    Value: computed(() => tm('reader_setup')['source'])
                },
                {
                    Alias: 'SourceName',
                    Value: computed(() => tm('reader_setup')['source_name'])
                },
                {
                    Alias: 'IntegrationType',
                    Value: computed(() => tm('reader_setup')['integration'])
                },
                {
                    Alias: 'Status',
                    Value: computed(() => tm('reader_setup')['status'])
                },
        ]);

        onMounted(() => {
            loadItems();
        })

        const loadItems = () => {
            isLoading.value = true;
            endpoint.get().then((res) => {
                tableBody.value = res.data;
                isLoading.value = false;
            })
        }

        const deleteReader = () => {
            isLoading.value = true;
            const id = selectedReaderIdForDelete.value;
            selectedReaderIdForDelete.value = null;
            endpoint.deleteReader(id).then((res) => {
                notifications.push({
                   title: tm('reader_setup')['reader_deleted'],
                   msg: tm('reader_setup')['reader_deleted_successfully']
               }, 'success', false, false)
                loadItems()
            })
        }
        


        return {
            actions,
            showCreateModal,
            selectedReaderId,
            isLoading,
            renderFunctions,
            tableBody,
            tableHead,
            loadItems,
            selectedReaderIdForDelete,
            deleteReader
            
        }

    }

}
</script>
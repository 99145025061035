import { php } from './../endpoints.js';

const apiKeys = {
    async get() {
        return php.get("/api-key").then((res) => res.data);
    },
    async getById(id) {
        return php.get(`/api-key/${id}`).then((res) => res.data);
    },
    async update(id, data) {
        return php.put(`/api-key/${id}`, data).then((res) => res.data);
    },
    async create(data) {
        return php.post(`/api-key`, data).then((res) => res.data);
    },
    async delete(id) {
        return php.delete(`/api-key/${id}`).then((res) => res.data);
    }
}

export default apiKeys;
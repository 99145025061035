<template>
    <div>
        <div class="p-relative">
            <h2 class="page-title">{{ $t('integrations.integrations') }}</h2>
            <label class="create-new-item" @click="showCreateModal = true">{{ $t('integrations.create_new') }}<font-awesome-icon icon="fa-regular fa-square-plus" /></label>
        </div>

        <!-- Table listing start -->
        <GlobXpanel class="mt-5">
            <GlobSpinner :isLoading="isLoading"></GlobSpinner>
            <GlobRow>
                <GlobCol :medium="12" class="mt-3">
                    <GlobTable
                    class="mt-5"
                    :maxHeight="75"
                    :data="{
                        head: tableHead,
                        body: tableBody

                    }"
                    :actions = "actions"
                    :itemRenderFunctions="renderFunctions"
                />
                </GlobCol>
            </GlobRow>
        </GlobXpanel>
        <!-- Table listing end -->

        <AddOrEdit v-if="showCreateModal" 
            :selectedItem = "selectedItem"
            @close="showCreateModal = false"
            @updated="showCreateModal = false, loadItems()"/>

         <!-- Delete reader confirm start -->
         <GlobConsequence  
            :title="$t('integrations.delete_integration')"
            :content="$t('integrations.delete_integration_confirm')"
            @close="selectedIdForDelete = false"
            @delete="deleteIntegration()"
            v-if="selectedIdForDelete"
        />
         <!-- Delete reader confirm stop -->
    </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

import AddOrEdit from './AddOrEdit.vue';

export default {
    components: {
        AddOrEdit,
    },
    setup() {
        const { tm } = useI18n();
        const notifications = useNotifications();
        const api = useApi();
        const endpoint = api.integrations;

        const isLoading = ref(false);
        const selectedItem = ref(false);
        const selectedIdForDelete = ref(null);

        const actions = computed(() => {
            return [
                {
                    name: tm('general')['button']['edit'],
                    type: "primary",
                    action: (obj) => {
                        selectedItem.value = obj;
                        showCreateModal.value = true;
                    }
                },
                {
                    name: tm('general')['button']['delete'],
                    type: "delete",
                    action: (obj) => {
                        selectedIdForDelete.value = obj.Id;
                    }
                }
            ];
        });

        const renderFunctions = computed(() => {
            return {
                Active(obj) {
                    if(obj.Active){
                        return '<span class="check-icon"><i class="fa-solid fa-check"></i></span>';
                    }
                    return '<span class="cross-icon"><i class="fa-solid fa-xmark"></i></span>';
                }
            }
        })


        const tableBody = ref([]);
        const tableHead = ref([
                {
                    Alias: 'Name',
                    Value: computed(() => tm('integrations')['name'])
                },
                {
                    Alias: 'Integration',
                    Value: computed(() => tm('integrations')['integration'])
                },
                {
                    Alias: 'Active',
                    Value: computed(() => tm('integrations')['active'])
                },
        ]);

        onMounted(() => {
            loadItems();
        })

        const loadItems = () => {
            isLoading.value = true;
            endpoint.get().then((res) => {
                tableBody.value = res.data;
                isLoading.value = false;
            })
        }

        const deleteIntegration = () => {
            isLoading.value = true;
            const id = selectedIdForDelete.value;
            selectedIdForDelete.value = null;
            endpoint.deleteIntegration(id).then((res) => {
                notifications.push({
                   msg: tm('integrations')['deleted_successfully']
               }, 'success', false, false)
                loadItems()
            })
        }


        /**  Crearte integration start */

        const showCreateModal = ref(false);


        /**  Crearte integration end */
        


        return {
            actions,
            showCreateModal,
            selectedItem,
            isLoading,
            renderFunctions,
            tableBody,
            tableHead,
            loadItems,
            selectedIdForDelete,
            deleteIntegration
            
        }

    }

}
</script>
<script setup>
import { ref, defineProps } from 'vue'
import { VuePDF, usePDF } from '@tato30/vue-pdf'

const props = defineProps({
    fileURL: String,
});

const isPDFNotLoaded = ref(true);
const scale = ref(1)
const page = ref(1)
const { pdf, pages } = usePDF(props.fileURL)

const onLoaded = () => {
	isPDFNotLoaded.value = false;
}
</script>

<template>
  <div class="p-relative pdf-viewer-component">
	<div v-if="pages>1" class="change-page-action">
		<button class="button button--secondary" @click="page = page > 1 ? page - 1 : page">
			Prev
		</button>
		<span>{{ page }} / {{ pages }}</span>
		<button class="button button--secondary" @click="page = page < pages ? page + 1 : page">
			Next
		</button>
	</div>
	<div class="change-scale-action" v-if="!isPDFNotLoaded">
      <button class="button button--secondary" :disabled="(scale * 100) <= 50" @click="scale = scale > 0.25 ? scale - 0.25 : scale">
        -
      </button>
      <span>{{ scale * 100 }}%</span>
      <button class="button button--secondary" @click="scale = scale < 2 ? scale + 0.25 : scale">
        +
      </button>
    </div>
	<VuePDF 
		class="pdf-viewer" 
		:class="isPDFNotLoaded ? 'pdf-error': ''"
		:pdf="pdf" 
		:page="page" 
		:scale="scale"
		@loaded="onLoaded"/>
	<p v-if="isPDFNotLoaded" class="pdf-error-message">
		{{ $t('general.file_not_found') }}
	</p>
  </div>
</template>

<style lang="scss">
.pdf-viewer-component{

	.pdf-viewer{
  	box-shadow: 0px 10px 15px 0px #00194008;
    border: 1px solid #DAE8FC;
	margin-top: 2rem;
	min-width: 595px;
	overflow: auto !important;
	height: 70vh;

	canvas{
		margin-left: auto !important;
		margin-right: auto !important;
	}
}

.pdf-error{
	min-width: 595px;
    min-height: 600px;
}

.pdf-error-message{
	position: absolute;
    top: 41%;
    margin: 0;
    font-size: 34px;
    opacity: .4;
    left: 31%;
}

	.change-page-action, .change-scale-action{
		text-align: center;
		margin-bottom: 12px;

		span{
			margin-right: 7px;
			margin-left: 7px;
		}
	}

	// .change-scale-action{
	// 	position: fixed;
	// 	top: 61px;
	// 	left: 24%;
	// 	z-index: 999;
	// 	margin-bottom: 3rem;
	// }
}

</style>
<template>
    <GlobRow class="mt-4">
        <GlobCol :medium="8">
            <label>{{$t('integrations.teneant_id')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.teneant_id')"
                    v-model="config.teneant_id"
                />
            </label>
        </GlobCol>
        <GlobCol :medium="6"></GlobCol>
    </GlobRow>
    <GlobRow class="mt-4">
        <GlobCol :medium="4">
            <label>{{$t('integrations.appication_id')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.appication_id')"
                    v-model="config.appication_id"
                />
            </label>
        </GlobCol>
        <GlobCol :medium="4">
            <label>{{$t('integrations.secret')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.secret')"
                    v-model="config.secret"
                />
            </label>
        </GlobCol>
        <GlobCol :medium="4">
            <label>{{$t('integrations.sender_name')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.sender_name')"
                    v-model="config.sender_name"
                />
            </label>
        </GlobCol>
        <GlobCol :medium="4" class="mt-4">
            <label>{{$t('integrations.email')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.email')"
                    v-model="config.email"
                />
            </label>
        </GlobCol>
    </GlobRow>
</template>

<script>
import { reactive, watch, onMounted, ref, onUnmounted } from 'vue';

export default {
    props: ['values'],
   setup(props, {emit}) {

        const config = reactive({
            'teneant_id': '',
            'appication_id': '',
            'secret': '',
            'email': '',
            'sender_name': '',
        })

        const isLoaded = ref(false)

        onMounted(() => {
            Object.assign(config, props.values)
            isLoaded.value = true;
        })

        onUnmounted(() => {
            Object.assign(config,{})
            emit('resetConfig')
        })


        const checkValidation = () => {
            if(!config.teneant_id 
                || !config.appication_id
                || !config.secret
                || !config.email
                || !config.sender_name)
            {
                emit('requiredFieldsFilled', false)
            }else{
                emit('requiredFieldsFilled', true)
            }
            emit('config', config)
        }

        watch(() => config.teneant_id, (newVal, oldVal) => {
            checkValidation()
        });

        watch(() => config.appication_id, (newVal, oldVal) => {
            checkValidation()
        });

        watch(() => config.secret, (newVal, oldVal) => {
            checkValidation()
        });

        watch(() => config.email, (newVal, oldVal) => {
            checkValidation()
        });

        watch(() => config.sender_name, (newVal, oldVal) => {
            checkValidation()
        });

        return {
            config,
            isLoaded
        }

   }

}
</script>
<template>
    <div class="json-data-viewer">
        <template v-if="jsonData">
            <json-viewer
                :value="jsonData"
                :expand-depth=100
                :expanded="true"
                copyable
                boxed
                sort>
            </json-viewer>
        </template>
        <template v-else>
            <div class="no-data-found">
                <p>{{ $t('general.no_data_found') }}</p>
            </div>
        </template>
    </div>
    
</template>

<script>
import { onMounted } from 'vue';
import 'vue-json-viewer/style.css'
export default {
	props: ['jsonData'],
    setup(props){
        return {
            ...props
        }
    }
}
</script>

<style lang="scss">
.json-data-viewer{
    .jv-container {
        background: #151718 !important;

        .jv-key{
            color: #BF40BF !important;
        }
        .jv-string{
            color: #088F8F !important;
        }

        .jv-item.jv-array,  .jv-item.jv-object{
            color: #088F8F !important;
        }

        .jv-more{
            background-color: #F9F6EE;
        }
    }

    .jv-container.jv-light{
        color: #088F8F !important;
    }

    div.no-data-found{
        background-color: #151718;
        color: white;
        border-radius: 4px;
        height: 89px;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin: 0;
            font-size: 18px;
            opacity: .5;
        }
    }
}
</style>
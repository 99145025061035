<template>
    <div ref="chartdiv" id="chartdiv" :style="{ width: '100%', height: height ? height : '500px' }"></div>
  </template>
  
  <script setup>
  import { defineProps, onMounted, onBeforeUnmount, ref } from 'vue';
  import * as am5 from '@amcharts/amcharts5';
  import * as am5xy from '@amcharts/amcharts5/xy';
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
  
  const props = defineProps({
    height: String,
    chartData: Array,
    seriesConfig: Array,
    toopTip: String 
  });

  let root;
  
  onMounted(() => {
    root = am5.Root.new("chartdiv");
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
  
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX:true,
    }));
  
    chart.get("colors").set("step", 3);
  
    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);
  
    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0,
      baseInterval: {
        timeUnit: "day",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root, { minorGridEnabled: true }),
      tooltip: am5.Tooltip.new(root, {})
    }));
  
    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));
  // Legend configuration
  let legend = chart.children.push(am5.Legend.new(root, {
    centerX: am5.p50,
    x: am5.p50,
    position: 'top'
  }));

     // Transform data and find unique series names
  const uniqueSeriesNames = new Set();
  const transformedData = props.chartData.map((record) => {
    const transformedRecord = { date: record.date };
    Object.keys(record).forEach((key) => {
      if (key !== 'date') {
        uniqueSeriesNames.add(key);
        transformedRecord[key] = record[key];
      }
    });
    return transformedRecord;
  });
  // Generate series dynamically
  uniqueSeriesNames.forEach((seriesName) => {
    const series = chart.series.push(am5xy.LineSeries.new(root, {
      name: seriesName,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: seriesName,
      valueXField: 'date',
      tooltip: am5.Tooltip.new(root, {
        labelText: props.toopTip ? props.toopTip : '{valueY}'
      })
    }));
    series.data.setAll(transformedData);
    series.appear(1000);
    series.show();
    // Add series to legend
    legend.data.push(series);
  });
    
  // chart.set("paddingBottom", 80);
    chart.appear(1000, 100);
  });
  
  onBeforeUnmount(() => {
    if (root) {
      root.dispose();
    }
  });
  </script>
  
  <style>

  </style>
  
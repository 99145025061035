<template>
    <div class="nav-status-actions">
        <div class="user-action" data-toggle="example-dropdown-1" @click="toggleControls()">
            <p class="user-name">{{ user.Name }}</p>
            <span class="user-name-icon">{{ user.Name.split('')[0] }}</span>

            <div class="user-action__controls" @mouseleave="closeControls" :class="isControlsToggled ? 'user-action__controls--active' : ''">
                <div class="user-action__controls-item">
                    <span class="d-flex center-items-horizontal" @click="logout()"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />{{ $t('menu_items.logout') }}</span>
                </div>
        	</div>
        </div>
        <span class="extra-action" v-if="false"><font-awesome-icon icon="fa-regular fa-bell" /></span>
        
        <div class="notifications-toggle-action">
            <span class="extra-action" :class="hasUnreadNotifications ? 'unread' : ''" v-on:click.prevent="toggleNotifications()"><font-awesome-icon icon="fa-regular fa-envelope" /></span>
            <Notifications :show="showNotification" @close="showNotification = false" @mouseleave="showNotification = false"/>
        </div>
        
        <!-- Settings dropdown start -->
        <span class="extra-action" @click="toggleControls('settings')"><font-awesome-icon icon="fa-solid fa-gear" />
            <div class="user-action__controls" @mouseleave="closeControls" :class="isSettingControlsToggled ? 'user-action__controls--active' : ''">
                <div class="user-action__controls-item d-grid">
                    <span class="d-flex center-items-horizontal" @click="$router.push({name: 'Readers'})">{{ $t('menu_items.reader_setup') }}</span>
                    <span class="d-flex center-items-horizontal" @click="$router.push({name: 'Integrations'})">{{ $t('menu_items.integrations') }}</span>
                    <span class="d-flex center-items-horizontal" @click="$router.push({name: 'Users'})">{{ $t('menu_items.users') }}</span>
                    <span class="d-flex center-items-horizontal" @click="$router.push({name: 'ApiKeys'})">{{ $t('menu_items.api-keys') }}</span>
                </div>
        	</div>
        </span>
        
        <!-- Settings dropdown end -->
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";

import Notifications from './NotificationCenter/Notifications.vue';

export default {
    components: {
        Notifications
    },
    setup() {
        const store = useStore();
        const user = computed(() => store.state.USER);
        const api = useApi();
        const router = useRouter();
        const notifications = useNotifications();
        const showNotification = ref(false);

        const logout = () => {
            api.logout().then((res) => {
                notifications.push({
                    title: 'Logged out',
                    msg: 'Logged out successfully'
                }, 'success', false, false)
                router.go();
            })
        }

        const isControlsToggled = ref(false)
        const isSettingControlsToggled = ref(false);

        const toggleControls = (type = null) => {
            if(type == 'settings')
            {
                isSettingControlsToggled.value = !isSettingControlsToggled.value
            }else{
                isControlsToggled.value = !isControlsToggled.value
            }
            
        }

        const closeControls = () => {
            isControlsToggled.value = false;
            isSettingControlsToggled.value = false;
        }

        const notificationsList = computed(() => store.state.NOTIFICATIONS);
        const hasUnreadNotifications = computed(() => notificationsList.value.some(i => i.unread));

        const toggleNotifications = () => {
            store.dispatch('FETCH_NOTIFICATIONS')
            showNotification.value = !showNotification.value;
        }

        return {
            user,
            logout,
            isControlsToggled,
            isSettingControlsToggled,
            toggleControls,
            closeControls,
            showNotification,
            toggleNotifications,
            hasUnreadNotifications
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-status-actions{
    margin-left: auto;
    display: flex;
    align-items: center;

    span.extra-action{
        position: relative;
        background-color: #F2F7FE;
        width: 35px;
        height: 36px;
        margin-right: 16px;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;


        svg{
            color: #0066FF;
        }

        .user-action__controls{
            right: -10px;
        }
    }
}
.user-action{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    cursor: pointer;
    margin-left: auto;
    padding-right: 16px;
    position: relative;
    p.user-name{
        margin: 0;
        font-size: 16px;
        color: #414950;
        font-weight: 500;
    }

    span.user-name-icon{
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #A5C2DD;
        border-radius: 10px;
    }

}

.user-action__controls{
    position: relative;
    z-index: 15;
    background-color: $white;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 0;
    background-color: $white;
    top: 37px;
    right: 15px;
    border-radius: 5px;
    margin-top: 10px;
    &-item {
        padding-left: 18px;
        padding-right: 18px;
        border: 1px solid $primary-btn-color;
        border-radius: 6px;
        font-size: 16px;
        cursor: pointer;
        span {
            white-space: nowrap;
            padding-top: 6px;
            padding-bottom: 6px;

            svg{
                color: $orange;
                margin-right: 6px;
                font-size: 17px;
            }
        }

        span:nth-child(n+2) {
            border-top: 1px solid #0066FF
        }
    }
    &--active {
        opacity: 1;
        visibility: visible;
    }
}

.user-action__controls::before{
    content: "";
    position: absolute;
    bottom: 100%; 
    left: 78%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent;
    border-bottom: 10px solid $primary-btn-color;
}

.notifications-toggle-action{
    position: relative;

    .extra-action.unread:after{
        content: "";
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100px;
        position: absolute;
        top: 8px;
        right: 7px;
        background-color: $red;
    }
}



</style>
<template>
    <div class="x-panel">
        <slot/>
    </div>
</template>

<style lang="scss" scoped>
.x-panel{
    box-shadow: 0px 10px 15px 0px #00194008;
    border-radius: 15px;
    background-color: $white;

    padding-top: 2rem;
    padding-left: 16px;
    padding-right: 16px;
}
</style>
<template>
    <div>
        <h2 class="page-title">{{ $t('dashboard.dashboard') }}</h2>
		<GlobSpinner :isLoading="isLoading" />
		<!-- Show progress cards start -->
        <GlobRow>
          <GlobCol :medium="4" class="dashboard-pregress-stat">
			   <div class="card-highlight incoming">{{$t('dashboard.incoming_documents_today')}}</div>
               <div class="card">
                <div class="card-section">
				  <div class="section-stat">
					<p>{{ counts.CountReceivedToday }}</p>
					<template v-if="counts.CountReceivedPerc > 100">
						<span class="progress up">
							<font-awesome-icon icon="fa-solid fa-arrow-right" />
							{{counts.CountReceivedPerc}}%
						</span>
					</template>
					<template v-else-if="counts.CountReceivedPerc <= 100">
						<span class="progress down">
							<font-awesome-icon icon="fa-solid fa-arrow-right" />
							{{counts.CountReceivedPerc}}%
						</span>
					</template>
					<template v-else>
						<span class="progress">
							0%
						</span>
					</template>
				  </div>
				  	<div class="section-stat">
						<p>{{ counts.CountReceivedYesterday }}</p>
						<span class="progress-prev">
							{{$t('dashboard.yesterday')}}
						</span>
					</div>
                </div>
              </div>
          </GlobCol>

		  <GlobCol :medium="4" class="dashboard-pregress-stat">
				<div class="card-highlight success">{{ $t('dashboard.processed_successful') }}</div>
				<div class="card">
				<div class="card-section">
					<div class="section-stat">
					<p>{{ counts.CountProcessedToday }}</p>
					<template v-if="counts.CountProcessedPerc > 100">
						<span class="progress up">
							<font-awesome-icon icon="fa-solid fa-arrow-right" />
							{{counts.CountProcessedPerc}}%
						</span>
					</template>
					<template v-else-if="counts.CountProcessedPerc <= 100">
						<span class="progress down">
							<font-awesome-icon icon="fa-solid fa-arrow-right" />
							{{counts.CountProcessedPerc}}%
						</span>
					</template>
					<template v-else>
						<span class="progress">
							0%
						</span>
					</template>
					</div>
					<div class="section-stat">
						<p>{{ counts.CountProcessedYesterday }}</p>
						<span class="progress-prev">
							{{$t('dashboard.yesterday')}}
						</span>
					</div>
				</div>
				</div>
			</GlobCol>

			<GlobCol :medium="4" class="dashboard-pregress-stat">
				<div class="card-highlight fail">{{$t('dashboard.processed_failed')}}</div>
				<div class="card">
				<div class="card-section">
					<div class="section-stat">
					<p>{{ counts.CountFailedToday }}</p>
					<template v-if="counts.CountFailedPerc > 100">
						<span class="progress up">
							<font-awesome-icon icon="fa-solid fa-arrow-right" />
							{{counts.CountFailedPerc}}%
						</span>
					</template>
					<template v-else-if="counts.CountFailedPerc <= 100">
						<span class="progress down">
							<font-awesome-icon icon="fa-solid fa-arrow-right" />
							{{counts.CountFailedPerc}}%
						</span>
					</template>
					<template v-else>
						<span class="progress">
							0%
						</span>
					</template>
					</div>
					<div class="section-stat">
						<p>{{ counts.CountFailedYesterday }}</p>
						<span class="progress-prev">
							{{$t('dashboard.yesterday')}}
						</span>
					</div>
				</div>
				</div>
			</GlobCol>
        </GlobRow>
		<!-- Show progress cards end -->

		<!-- Show chart start -->
    <GlobRow>
		<GlobCol :medium="12" v-if="chartData && chartData.length">
			<GlobXpanel class="mt-5">
				<LineChart 
					:chartData="chartData" 
					:height="'55vh'"
					:toopTip="'{name}:{valueY}'"></LineChart>
			</GlobXpanel>
		</GlobCol>
    </GlobRow>
		<!-- Show chart end -->

    </div>
</template>


<script>
import  { onMounted, ref } from 'vue';
import { useApi } from "@/plugins/api";
import LineChart from './../components/charts/line-chart.vue'

export default {
  name: "Home",
  components: {
	LineChart
  },
  setup() {
	const api = useApi();
	const endpoint = api.dashboard;
	const chartData = ref([]);
	const counts = ref({});
	const isLoading = ref(false);

	onMounted(() => {
            loadData();
    })

	const loadData = () => {
		isLoading.value = true;
		endpoint.get().then((res) => {
			chartData.value = res.analytics.map((item) => {
				item.date = new Date(item.date).getTime();
				return item;
			});


			res.counts.CountReceivedPerc = 0;
			res.counts.CountProcessedPerc = 0;
			res.counts.CountFailedPerc = 0;

			if (res.counts.CountReceivedYesterday === 0) {
				res.counts.CountReceivedPerc = res.counts.CountReceivedToday;
			}else{
				res.counts.CountReceivedPerc = Math.round(((res.counts.CountReceivedToday/res.counts.CountReceivedYesterday)*100), 2);
			}
				

			if (res.counts.CountProcessedYesterday === 0) {
				res.counts.CountProcessedPerc = res.counts.CountProcessedToday;
			}else{
				res.counts.CountProcessedPerc = Math.round(((res.counts.CountProcessedToday/res.counts.CountProcessedYesterday)*100), 2);
			}


			if (res.counts.CountFailedYesterday === 0) {
				res.counts.CountFailedPerc = res.counts.CountFailedToday;
			}else{
				res.counts.CountFailedPerc = Math.round(((res.counts.CountFailedToday/res.counts.CountFailedYesterday)*100), 2);
			}


			counts.value = res.counts;

			isLoading.value = false;
		})
    }

	return  {
		chartData,
		counts,
		isLoading
	}

  }
};
</script>

<style lang="scss" scoped>
.card {
  height: 235px;
  width: 453px;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px #00194008;
  position: relative;

  .card-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .section-stat {
      flex-basis: 50%;
      text-align: center;
      font-weight: 600;

      p {
        margin: 0;
        font-size: 50px;
        color: #1E1E1E;
      }

      .progress {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        font-size: 22px;
        font-weight: 400;
        color: #1E1E1E;

        svg {
          font-size: 30px;
        }
      }

      .progress.up svg {
        color: #42CD00;
      }

      .progress.down svg {
        color: #FF981F;
      }

      .progress-prev {
        color: $dark-blue-shade;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

.dashboard-pregress-stat {
  position: relative;
  padding-left: 0 !important;

  .card-highlight {
    width: 303px;
    height: 44px;
    border-radius: 10px;
    position: absolute;
    z-index: 9;
    top: -8%;
    left: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: $dark-blue-shade;
    font-weight: 400;
  }

  .card-highlight.incoming {
    background: linear-gradient(0deg, rgba(0, 102, 255, 0.2), rgba(0, 102, 255, 0.2)),
                linear-gradient(0deg, $blue-shade, $blue-shade);
    border: 1px solid #0066FF33;
  }

  .card-highlight.success {
    background: linear-gradient(0deg, rgba(0, 146, 0, 0.2), rgba(0, 146, 0, 0.2)),
                linear-gradient(0deg, #CCFFCC, #CCFFCC);
    border: 1px solid #00920033;
  }

  .card-highlight.fail {
    background: linear-gradient(0deg, rgba(209, 0, 0, 0.2), rgba(209, 0, 0, 0.2)),
                linear-gradient(0deg, #FFDAD1, #FFDAD1);
    border: 1px solid #D1000033;
  }
}

.page-title{
	margin-bottom: 3rem;
}
</style>

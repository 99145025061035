<template>
    <GlobModal
        :width="'500px'"
        :title="title"
        @close="$emit('close')"
        :show="true"
    > 
        <div class="consequence">
            <GlobRow>
                <GlobCol :medium="12">
                    <p>{{ content }}</p>
                </GlobCol>
            </GlobRow>
            <GlobRow>
                <GlobCol :medium="12">
                    <button class="button button--primary mr-3" @click="$emit('delete')">{{ confirmButtonText ? confirmButtonText : $t('general.button.delete') }}</button>
                    <button class="button button--cancel" @click="$emit('close')">{{ cancelButtonText ? cancelButtonText : $t('general.button.cancel') }}</button>
                </GlobCol>
            </GlobRow>
        </div>
    </GlobModal>
</template>

<script>
export default {
    props: ['title', 'content', 'confirmButtonText', 'cancelButtonText'],
    setup(props) {

        return {
            ...props
        }
    }

}
</script>

<style lang="scss" scoped>
    p{
        font-size: 17px;
        margin-bottom: 28px;
    }
</style>
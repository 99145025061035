import { createI18n } from 'vue-i18n'
import Cookies from "js-cookie";

const savedLang = Cookies.get('ORING_LANG');

function loadLocaleMessages() {
	const contexts = [
		require.context('@/locales/general', true, /[A-Za-z0-9-_,\s]+\.json$/i),
		require.context('@/locales/navbar', true, /[A-Za-z0-9-_,\s]+\.json$/i),
		require.context('@/locales/message-center', true, /[A-Za-z0-9-_,\s]+\.json$/i),

		require.context('@/locales/home', true, /[A-Za-z0-9-_,\s]+\.json$/i),
		require.context('@/locales/documents', true, /[A-Za-z0-9-_,\s]+\.json$/i),
		require.context('@/locales/readers', true, /[A-Za-z0-9-_,\s]+\.json$/i),
		require.context('@/locales/integrations', true, /[A-Za-z0-9-_,\s]+\.json$/i),
		require.context('@/locales/users', true, /[A-Za-z0-9-_,\s]+\.json$/i),
		require.context('@/locales/api-keys', true, /[A-Za-z0-9-_,\s]+\.json$/i),
	];
	const messages = {};
  
	contexts.forEach((context) => {
	  context.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
		  const locale = matched[1];
		  if (!messages[locale]) messages[locale] = {};
		  const moduleMessages = context(key);
		  Object.assign(messages[locale], moduleMessages);
		}
	  });
	});
  
	return messages;
  }

const messages = loadLocaleMessages();
console.log('messages', messages)
export const i18n = createI18n({
	

	locale: savedLang ? savedLang : 'en-GB',
	messages: messages,
	legacy: false
})

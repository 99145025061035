export const generateId = (name) => {
  name = name ? `${name}`.replace(" ", "-").toLowerCase() : false;
  return (
    (name ? name + "-" : "") +
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
};

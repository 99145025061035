<template>
        <GlobModal
                :width="'800px'"
                :title="$t('reader_setup.interpreters.model')+': ' +selectedInterpreter.Name"
                @close="$emit('close')"
                :show="true"
            >  
                <CHATGPTCUSTOM  
                    @close="$emit('close')"
                    @config="(values) =>  $emit('config', values)"
                    :values="values"
                    v-if="selectedInterpreter.Code == 'CHATGPTCUSTOM'">
                </CHATGPTCUSTOM>
        </GlobModal>
</template>

<script>
import { reactive, computed, onMounted, ref, onUnmounted } from 'vue';
import CHATGPTCUSTOM from './CHATGPTCUSTOM.vue';

export default {
    props: ['selectedInterpreter', 'values'],
    components: {
        CHATGPTCUSTOM
    },
    setup(props, {emit}) {

        onMounted(() => {
            console.log('selectedInterpreter', props.selectedInterpreter)
        })

        return {
            ...props
        }

   }

}
</script>
import { createRouter, createWebHistory } from "vue-router";
import Login from '@/views/auth/Login.vue';
import Reader from "@/views/modules/readers/Index.vue";
import Integration from "@/views/modules/integrations/Index.vue";
import User from "@/views/modules/users/Index.vue";
import ApiKeys from "@/views/modules/api-keys/Index.vue";

const routesList = [
  {
        name: "Login",
        path: "/login",
        component: Login
    },
    {
        name: "Readers",
        path: "/readers",
        translation: 'menu_items.readers',
        component: Reader
    },
    {
      name: "Integrations",
      path: "/integrations",
      translation: 'menu_items.integrations',
      component: Integration
  	},
	{
		name: "Users",
		path: "/users",
		translation: 'menu_items.users',
		component: User
	},
  {
		name: "ApiKeys",
		path: "/api-keys",
		translation: 'menu_items.api-keys',
		component: ApiKeys
	}
];


import menuItems from './menu-items.js';

export const routes = [...routesList, ...menuItems];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

<template>
    <GlobModal
       :width="'1000px'"
       :title="$t('reader_setup.source_section.edit_context')"
       @close="$emit('close')"
       :show="true"
   >          
   <GlobSpinner :isLoading="isLoading" />
        <GlobRow class="mt-4">
           <GlobCol :medium="12">
               <label>{{$t('reader_setup.source_section.context')}}
                   <InputDynamic  
                       :type="'textarea'"
                       :placeholder="$t('reader_setup.source_section.context')"
                       :rows="35"
                       v-model="context"
                   />
               </label>
           </GlobCol>
       </GlobRow>

       <GlobRow class="mt-8">
           <GlobCol>
               <button class="button button--primary filter-trigger mr-2" @click="save()">{{  $t('general.button.save') }}</button>
               <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
           </GlobCol>
       </GlobRow>
   </GlobModal>
</template>

<script>
import { ref } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

export default {
   props: ['readerId', 'readerSource'],
   setup(props, {emit}) {

       const { tm } = useI18n();
       const api = useApi();
       const notifications = useNotifications();
       const isLoading = ref(false);
       const endpoint = api.readers;

       const context = ref(props.readerSource.GeneralSourceContext);


        const save = () => {
           isLoading.value = true;
           endpoint.saveSourceContext(props.readerId, props.readerSource.ReaderSourceId, {
                context: context.value
           }).then(() => {
                notifications.push({
                    title: tm('reader_setup')['source_section']['context_saved'],
                    msg: tm('reader_setup')['source_section']['context_saved_successfully']
                }, 'success', false, false)
                emit('saved', context);
                isLoading.value = false;
            });
       }

       return {
            isLoading,
            context,
            save
       }

   }
}
</script>
<template>
		<GlobSpinner :isLoading="isLoading"/>
		<GlobRow class="mt-4">
			<GlobCol :medium="4">
				<label>
					{{$t('user.fields.name')}}*
					<InputDynamic
					:fullWidth="true"
					:type="'text'"
					:placeholder="$t('user.fields.name')"
					v-model="user.form.Name"
					/>
				</label>
			</GlobCol>
			<GlobCol :medium="4">
				<label>
					{{$t('user.fields.last_name')}}*
					<InputDynamic
					:fullWidth="true"
					:type="'text'"
					:placeholder="$t('user.fields.last_name')"
					v-model="user.form.LastName"
					/>
				</label>
			</GlobCol>
			<GlobCol :medium="4">
				<label>
					{{$t('user.fields.email')}}*
					<InputDynamic
					:fullWidth="true"
					:type="'email'"
					:placeholder="$t('user.fields.email')"
					v-model="user.form.Email"
					/>
				</label>
			</GlobCol>
		</GlobRow>
		<GlobRow class="mt-4">
			<GlobCol :medium="4">
				<label>
					{{$t('user.fields.password')}}
					<InputDynamic
					:fullWidth="true"
					:type="'password'"
					:placeholder="$t('user.fields.password')"
					v-model="user.form.Password"
					/>
				</label>
			</GlobCol>
			<GlobCol :medium="4">
				<label>
					{{$t('user.fields.confirm_password')}}
					<InputDynamic
					:fullWidth="true"
					:type="'password'"
					:placeholder="$t('user.fields.confirm_password')"
					v-model="user.form.ConfirmPassword"
					/>
				</label>
			</GlobCol>
			<InputToggle :label="$t('integrations.active')" v-model="user.form.AccountActive" v-if="!isLoading"/>
			
			<GlobCol :medium="12" class="mt-4">
				<p class="text-danger" v-if="errorMessage">{{errorMessage}}</p>
				<button @click="save" class="button button--primary mt-4 mr-3" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
				<button @click="$emit('close')" class="button button--cancel mt-4">{{$t('general.button.cancel')}}</button>
			</GlobCol>
		</GlobRow>
</template>

<script>
import {onMounted, reactive, ref, computed } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/modules/users/common.js";

export default {
  props: ['roles'],
  setup(props, {emit}) {
    const { tm } = useI18n();
	const endpoint = computed(() => api.users);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const user = reactive({
		form: {
			Name: "",
            LastName: "",
            Email: "",
			CellPhone: "",
            Password: "",
            ConfirmPassword: "",
			AccountActive: true,
		},
	});


	onMounted(()=> {

	})

    const save = () => {
		
		errorMessage.value = validateForm(user.form);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;
		endpoint.value.create(user.form).then((res) => {
			isLoading.value = false;
			if(res.success){
				notifications.push({
                   title: tm('user')['create_success'],
                   msg: tm('user')['create_success']
               }, 'success', false, false)
				emit('created')
				emit('close')
			}else{
				for(let errorField in res.errors){
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}

			
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return !user.form.Name || !user.form.LastName || !user.form.Email  ? true : false
	})


    return {
      save,
	  errorMessage,
	  user,
	  isLoading,
	  isSaveButtonDisabled,
    };
  },
};
</script>

<style lang="scss">
</style>

import { php } from './../endpoints.js';

const readers = {
    async get(data) {
        return php.get("/readers").then((res) => res.data);
    },
    async getDependencies() {
        return php.get("/readers/get-dependencies").then((res) => res.data);
    },
    async getById(readerId) {
        return php.get(`/readers/${readerId}`).then((res) => res.data);
    },
    async store(data) {
        return php.post("/readers", data).then((res) => res.data);
    },
    async update(readerId, data) {
        return php.put(`/readers/${readerId}`, data).then((res) => res.data);
    },
    async deleteReader(readerId) {
        return php.delete(`/readers/${readerId}`).then((res) => res.data);
    },

    //Source start
    async getSourceDetails(readerId, readerSourceId) {
        if(readerSourceId){
            return php.get(`/readers/${readerId}/source/${readerSourceId}`).then((res) => res.data);
        }
        return php.get(`/readers/${readerId}/source`).then((res) => res.data);
    },
    async saveSource(readerId,  data) {
        return php.post(`/readers/${readerId}/source`, data).then((res) => res.data);
    },
    async deleteSource(readerId,  sourceId) {
        return php.delete(`/readers/${readerId}/source/${sourceId}`).then((res) => res.data);
    },
    async saveSourceContext(readerId, sourceId, data) {
        return php.put(`/readers/${readerId}/source/${sourceId}/context`, data).then((res) => res.data);
    },
    async getReaderSourceCustomers(readerId, sourceId){
        return php.get(`/readers/${readerId}/source/${sourceId}/customers`).then((res) => res.data);
    },
    async saveReaderSourceCustomers(readerId, sourceId, data){
        return php.post(`/readers/${readerId}/source/${sourceId}/customers`, data).then((res) => res.data);
    },
    async saveApprovalCustomer(readerId, sourceId, data){
        return php.post(`/readers/${readerId}/source/${sourceId}/approval-customer`, data).then((res) => res.data);
    },
    async deleteApprovalCustomer(readerId, sourceId, data){
        console.log('data', data)
        return php.delete(`/readers/${readerId}/source/${sourceId}/approval-customer`,{params: data}).then((res) => res.data);
    },
    //Source end
}

export default readers;
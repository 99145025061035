<template>
    <teleport to="#modals">
        <div class="modal"
            v-show="show">
            <div ref="popup" class="modal__popup"
            :style="{
                    width: width || null,
                    height: height || null
                    }">
                <div class="modal__header">
                    <p>{{ title }}</p>
                    <button @click="handleClose" class="modal__close"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal__content" 
                :style="`${minContentHeight ? `min-height: ${minContentHeight};` : ''} ${maxContentHeight ? `max-height: ${maxContentHeight};` : ''}`"
                    >
                    <slot  />
                </div>
            </div>
            <div class="modal__backdrop"></div>
        </div>
    </teleport>
</template>

<script>
export default {
    emits: ["close"],
    props: {
        show: {
            type: Boolean,
            required: false
        },
        width: {
            type: String,
            required: false
        },
        height: {
            type: String,
            required: false
        },
        minContentHeight: {
            type: String,
            required: false
        },
        maxContentHeight: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: false
        },
    },
    setup(props, { emit }) {
        const handleClose = () => {
            emit("close");
        };

        return {
            handleClose
        };
    }
};
</script>

<style lang="scss" scoped>
.modal {
    &__popup {
        position: fixed;
        border: 1px solid #DAE8FC;
        box-shadow: 0px 10px 30px 0px #00194026;
        border-radius: 10px;
        z-index: 12;
        margin: 1rem 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 90vw;
        max-height: 90vh;
        background-color: $white;
        overflow: unset;
    }

    &__content {
        overflow-y: auto;
        max-height: 78vh;
        padding-left: 23px;
        padding-right: 23px;
        padding-bottom: 28px;
    }

    &__header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid $white;
        display: flex;
        justify-content: space-between;
        height: 55px;
        padding-top: 13px;
        padding-left: 22px;
        padding-right: 17px;

        p {
            margin: 0;
            color: #414950;
            font-size: 20px;
            font-weight: 600;
        }
    }


    &__close {
        background-color: transparent;
        border: none;
        margin-bottom: 12px;
        color: $white;
        cursor: pointer;

        &:focus {
            outline: 0;
        }

        i {
            font-size: 23px;
            color: #A0A4A7;
        }
    }

    &__backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        display: block;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 11;
    }
}

@keyframes blink {
    from {
        opacity: 0.7;
    }

    to {
        opacity: 1;
    }
}</style>

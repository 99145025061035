const fields = {
    'OFF365MAIL': [
        {
            'key': 'Tenant',
            'name': 'reader_setup.source_section.app_id',
            'required': true,
            'type': 'text',
             
        },
        {
            'key': 'ApplicationId',
            'name': 'reader_setup.source_section.tenant_id',
            'required': true,
            'type': 'text'
        },
        {
            'key': 'SecretId',
            'name': 'reader_setup.source_section.secret',
            'required': true,
            'type': 'text'
        },
    ],
    'SHAREPO': [
        {
            'key': 'Tenant',
            'name': 'reader_setup.source_section.app_id',
            'required': true,
            'type': 'text'
             
        },
        {
            'key': 'ApplicationId',
            'name': 'reader_setup.source_section.tenant_id',
            'required': true,
            'type': 'text'
        },
        {
            'key': 'SecretId',
            'name': 'reader_setup.source_section.secret',
            'required': true,
            'type': 'text'
        },
        {
            'key': 'Email',
            'name': 'reader_setup.source_section.email',
            'required': true,
            'type': 'email',
            'class': 'mt-3'
        },
        {
            'key': 'RootFolder',
            'name': 'reader_setup.source_section.root_folder',
            'required': false,
            'type': 'text',
            'class': 'mt-3'
        },
        {
            'key': 'FilePrefixes',
            'name': 'reader_setup.source_section.file_prefixes',
            'required': false,
            'type': 'text',
            'class': 'mt-3'
        },  
    ]
}

export default fields;


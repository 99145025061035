<template>
    <div class="input-container">
        <select class="mas-input" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
            style="padding: .5rem 1rem;">
            <option class="mas-input::placeholder" value="" selected>{{ placeholder }}</option>
            <option v-for="item in data" :value="item.value == 0 || item.value ? item.value : item.name"
                :key="'select-option-' + item.value">{{
                    item.name ? item.name : item.value
                }}</option>
        </select>
        <i class="fa fa-times" v-if="modelValue != null && showReset" @click="$emit('update:modelValue', null)" />
    </div>
</template>

<script>
import { props } from './common';
export default {
    props: props,
};
</script>

<style lang="scss" scoped>
select {
    font-size: 17px;
    border: 1px solid #DAE8FC;
    color: #00194099;
    border-radius: 6px;
}
</style>

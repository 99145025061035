<template>
  <div
    class="ma-notifications"
    v-show="show"
    @mouseleave="
      closeContextMenu();
      !showNewMessage && $emit('mouseleave');
    "
  >
    <div class="ma-notifications__filter">
      <router-link :to="'/messenger'" :target="'_blank'"><b>{{$t('message_center.messages')}}</b></router-link>
      <div class="ma-notifications__filter-buttons">
        <button
          class="ma-notifications__filter-button"
          :class="showNewMessage ? 'ma-notifications__filter-button--active' : ''"
          @click="showNewMessage = !showNewMessage"
          style="border: none"
        >
          <i class="fas fa-pen"></i>
        </button>
        <button
          class="ma-notifications__filter-button"
          :class="filters.includes('success') ? 'ma-notifications__filter-button--active' : ''"
          @click="addFilter('success')"
        >
          <i class="fas fa-check"></i>
        </button>
        <button
          class="ma-notifications__filter-button"
          :class="filters.includes('warning') ? 'ma-notifications__filter-button--active' : ''"
          @click="addFilter('warning')"
        >
          <i class="fas fa-exclamation-triangle"></i>
        </button>
        <button
          class="ma-notifications__filter-button"
          :class="filters.includes('error') ? 'ma-notifications__filter-button--active' : ''"
          @click="addFilter('error')"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div v-if="showNewMessage" class="ma-notifications__newmessage">
      <InputDynamic 
        :type="'virtualselect'"
        :placeholder="'Recepient'"
        :data="users.map((user) => {
          return {
            name:  user.Name,
            value: user.Id
          }
        })"
        v-model="message.toUser"
        ></InputDynamic>

		<InputDynamic 
        :type="'virtualselect'"
        :placeholder="$t('general.fields.priority')"
        :data="severities.map((severity) => {
          return {
            name:  severity.name,
            value: severity.val
          }
        })"
        v-model="message.severity"
        ></InputDynamic>
      <input type="text" v-model="message.title" :placeholder="$t('general.fields.subject')" />
      <div class="ma-link-input">
        <input type="text" v-model="message.link" :placeholder="$t('general.fields.link')" />
        <button @click="setCurrentPath" class="ma-link-input__button" :title="$t('message_center.add_current_path')">
          <i class="fas fa-link"></i>
        </button>
      </div>
      <textarea v-model="message.msg" :placeholder="$t('general.fields.message')" />
      <button  @click="sendMessage" class="button button--primary">{{$t('general.button.send')}}</button>
    </div>
    <div v-else class="ma-notifications__messages">
      <div
        v-for="notification in filteredNotifications"
        :key="notification.id"
        class="ma-notifications__item"
        :class="`${notification.unread ? 'ma-notifications__item--unread' : ''} ${notification.severity}`"
        @click="markAsRead(notification.id)"
        @mouseleave="markAsRead(notification.id, notification.severity)"
      >
        <div class="ma-notifications__item-right">
          <div class="ma-notifications__item-title">
            {{ notification.title }}
            <span v-if="getUserFromId(notification.from)"
              ><i style="font-size: .6rem;" class="fas fa-user"></i>
              {{ getUserFromId(notification.from) && getUserFromId(notification.from).Name }}</span
            >
            <span><i style="font-size: .6rem;" class="fas fa-clock"></i>{{ formatTime(notification.time) }}</span>
          </div>
          <div class="ma-notifications__item-content" v-html="notification.msg"></div>
        </div>
        <div class="ma-notifications__item-after">
          <div class="ma-notifications__item-menu" :class="ctxMenu.id === notification.id ? 'active' : ''">
            <i @click="openContextMenu(notification)" class="fas fa-ellipsis-h"></i>
            <ul @mouseleave="closeContextMenu()">
              <li>
                <button @click="forwardMessage"><i class="fas fa-share-square"></i>{{$t('general.button.forward')}}</button>
              </li>
              <li v-if="getUserFromId(notification.from)">
                <button @click="replyMessage"><i class="fas fa-reply"></i>{{$t('general.button.respond')}}</button>
              </li>
              <li>
                <button @click="removeMessage"><i class="fas fa-trash"></i>{{$t('general.button.delete')}}</button>
              </li>
            </ul>
          </div>
          <router-link target="_blank" :to="notification.link" v-if="notification.link"
            ><i class="fas fa-external-link-alt"></i
          ></router-link>
        </div>
      </div>
      <div
        class="ma-notifications__item"
        style="opacity: 1; background-color: $white; text-align: center; width: 100%; border-top: 1px solid #D3D3D3;"
        v-if="notificationsLength > limit"
      >
        <div class="ma-notifications__item-right" style="width: 100%; max-width: none;">
          <router-link :to="'/messenger'" :target="'_blank'">
            <div class="ma-notifications__item-title" style="color: black;">
              {{$t('general.button.show_all')}}
            </div>
            <div class="ma-notifications__item-content">
              <b>{{$t('message_center.you_have_new_notificartions', {count: (notificationsLength - limit)})}}</b>
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="ma-notifications__item"
        style="text-align: center; width: 100%;"
        v-if="filteredNotifications.length < 1"
      >
        <div class="ma-notifications__item-right" style="width: 100%; max-width: none;">
          <div class="ma-notifications__item-title">
            {{$t('message_center.no_notifications')}}
          </div>
          <div class="ma-notifications__item-content">{{$t('message_center.empty_message')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useApi } from "@/plugins/api";
import InputDynamic from "../../../components/inputs/input-dynamic.vue";
import { useNotifications } from "@/plugins/notifications";

export default {
    props: ["show"],
    data() {
        return {
            api: null,
            messageAPI: null,
            filters: [],
            users: [],
            showNewMessage: false,
            ctxMenu: {},
            limit: 50,
            notificationPlugin: useNotifications(),
            message: {
                msg: "",
                title: "",
                toUser: null,
                severity: null,
                link: "",
            },
        };
    },
    computed: {
        ...mapState({
            notifications: function (state) {
                return state.NOTIFICATIONS;
            },
            notificationsLength: (state) => state.NOTIFICATIONS.length,
            user: (state) => state.USER,
        }),
        filteredNotifications() {
            return this.filters.length > 0
                ? this.notifications.filter((i) => this.filters.includes(i.severity)).slice(0, this.limit)
                : this.notifications.slice(0, this.limit);
        },
        severities() {
            return [
                {
                    name: this.$t('message_center.low'),
                    val: "low",
                },
                {
                    name: this.$t('message_center.normal'),
                    val: "normal",
                },
                {
                    name: this.$t('message_center.urgent'),
                    val: "urgent",
                },
            ];
        }
    },
    mounted() {
        this.api = useApi().users;
        this.messageAPI = useApi().messages;
        this.getUsers();
    },
    methods: {
        openContextMenu(notification) {
            if (this.ctxMenu.id === notification.id) {
                this.closeContextMenu();
                return;
            }
            if (this.ctxMenu.id !== notification.id) {
                this.ctxMenu = notification;
            }
        },
        closeContextMenu() {
            this.ctxMenu = {};
        },
        forwardMessage() {
            this.message.msg = this.ctxMenu.msg;
            this.message.title = `FW: ${this.ctxMenu.title}`;
            this.message.link = this.ctxMenu.link;
            this.message.severity = this.ctxMenu.severity;
            this.closeContextMenu();
            this.showNewMessage = true;
        },
        replyMessage() {
            this.message.title = `RE: ${this.ctxMenu.title}`;
            this.message.severity = this.ctxMenu.severity;
            this.message.link = this.ctxMenu.link;
            this.message.toUser = this.ctxMenu.from;
            this.closeContextMenu();
            this.showNewMessage = true;
        },
        removeMessage() {
            this.messageAPI.delete(this.ctxMenu.id).then((res) => {
                this.$store.dispatch("FETCH_NOTIFICATIONS");
                this.notificationPlugin.push({
                    title: this.$t('message_center.erased'),
                    msg: this.$t('message_center.delete_notification_success_message', { notification: this.ctxMenu.title }),
                }, "success", false, false);
            });
        },
        setCurrentPath() {
            this.message.link = this.$route.path;
        },
        getUserFromId(id) {
            return this.users.find((user) => user.Id == id);
        },
        sendMessage() {
            if (this.message.msg.length > 0 && this.message.toUser) {
                this.messageAPI
                    .send({
                    MessageType: "message",
                    MessageSubject: this.message.title,
                    MessageText: this.message.msg,
                    SenderAccountId: this.user.Id,
                    ReceiverAccountId: this.message.toUser,
                    Severity: this.message.severity,
                    IsRead: false,
                    Link: this.message.link,
                })
                    .then((res) => {
                    this.showNewMessage = false;
                    this.$emit('close');
                    this.message.toUser = null;
                    this.message.severity = null;
                    this.message.msg = "";
                    this.message.title = "";
                    this.message.link = "";
                    this.notificationPlugin.push({
                        title: this.$t('message_center.send'),
                        msg: this.$t('message_center.message_send_success'),
                    }, "success", false, false);
                });
            }
            else {
                this.notificationPlugin.push({
                    title: this.$t('message_center.send'),
                    msg: this.$t('message_center.send_message_warning'),
                }, "error", false, false);
            }
        },
        getUsers() {
            this.api.get().then((res) => {
                this.users = res.data;
            });
        },
        formatTime(time) {
            return `${time.toLocaleDateString()} ${time.getHours()}:${time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()}`;
        },
        markAsRead(id, severity) {
            if (!severity) {
                this.$store.commit("READ_NOTIFICATION", id);
            }
            else if (severity != "error") {
                this.$store.commit("READ_NOTIFICATION", id);
            }
        },
        getIcon(severity) {
            let icon = "";
            switch (severity) {
                case "warning":
                    icon = "exclamation-triangle orange";
                    break;
                case "error":
                    icon = "times red";
                    break;
                case "success":
                    icon = "check green";
                    break;
            }
            return icon;
        },
        addFilter(filter) {
            if (this.filters.includes(filter)) {
                this.filters.splice(this.filters.indexOf(filter), 1);
            }
            else {
                this.filters.push(filter);
            }
        },
    },
    components: { InputDynamic }
};
</script>

<style lang="scss">
.ma-notifications {
  position: absolute;
  right: 15px;
  top: 48px;
  background-color: $white;
  border-radius: 6px;
  width: 550px;
  z-index: 14;
  border : 1px solid $primary-btn-color;
  a{
    text-decoration: none;
  }
  &__newmessage {
    padding: 1rem;
    input,
    textarea {
      padding-left: 0.5rem;
      display: block;
      width: 100%;
      margin-bottom: 0.5rem;
      border: 1px solid $blue-shade;
      border-radius: 6px;
      padding-left: 13px;
      padding-right: 13px;
      height: 44px;
    }
    select {
      border: 1px solid $blue-shade;
      border-radius: 6px;
      width: 100%;
      padding-left: 13px;
      padding-right: 13px;
      height: 44px;
    }
  }
  &__messages {
    max-height: 50vh;
    overflow-y: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &__filter {
    position: sticky;
    z-index: 2;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: $white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid $blue-shade;
    a{
      color: $dark-blue-shade;
    }
    &-buttons {
      margin-left: auto;
    }
    &-button {
      margin-left: 1rem;
      border: 2px solid $primary-btn-color;
      color: $primary-btn-color;
      border-radius: 100px;
      width: 1.6rem;
      height: 1.6rem;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      &:focus {
        outline: 0;
      }
      i {
        font-size: 13px;
      }
      &--active {
        color: $white;
        background-color: $primary-btn-color;
      }
    }
  }
  &__item {
    width: 100%;
    transition: all 0.2s ease-in-out;
    display: flex;
    opacity: 0.6;
    padding-left: 12px;
    border-bottom: 3px solid $blue-shade;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &:active {
      background-color: $white;
    }
    &-right {
      padding: 0.5rem;
      max-width: 60%;
    }
    &-after {
      margin-left: auto;
      justify-self: flex-end;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      > * {
        margin-right: 1rem;
      }
      a {
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &-title {
      font-weight: 700;
      span {
        font-weight: 400;
        font-size: 0.8rem;
        color: gray;
        margin-left: 0.3rem;
        display: inline-flex;
        align-items: center;
        i {
          margin-right: 0.3rem;
        }
      }
    }
    &-content {
      flex: 1;
    }
    &--unread {
      opacity: 1;
      background-color: $paleBlueGray;
    }
    &-menu {
      position: relative;
      z-index: 100;
      cursor: pointer;
      ul {
        transition: all 0.3s ease-in-out;
        position: absolute;
        max-height: 0;
        overflow: hidden;
        padding: 0;
        visibility: hidden;
        border-radius: 4px;
        right: 0;
        list-style-type: none;
        z-index: 100;
        button {
          transition: all 0.3s ease-in-out;
          border: none;
          background-color: $white;
		  padding: 9px;
    	  font-size: 16px;
          margin: 0;
          width: 100%;
          display: block;
          text-align: left;
		  font-weight: 400;
    	  color: $dark-blue-shade;
          display: flex;
          align-items: center;
          i {
            margin-right: 0.5rem;
			color: $orange;
          }
          &:focus {
            outline: 0;
          }
        }
        li {
          border-top: 1px solid $primary-btn-color;
          &:first-child {
            border: none;
            button {
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
            }
          }
          &:last-child {
            button {
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
        }
      }
      &.active {
        ul {
          max-height: 200px;
          visibility: visible;
          margin: 0;
		  border: 1px solid $primary-btn-color;
        }
      }
    }
  }
}

.ma-link-input {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  input {
    flex: 1;
    margin-bottom: 0;
  }
  &__button {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100px;
    background-color: $primary-btn-color;
    color: $white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-left: 0.5rem;
    &:focus {
      outline: 0;
    }
  }
}

.ma-notifications::before{
    content: "";
    position: absolute;
    bottom: 100%; 
    left: 96%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent;
    border-bottom: 10px solid $primary-btn-color;
}
</style>

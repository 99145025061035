<template>
	<div class="row">
		<slot/>
	</div>
</template>

<style lang="scss">
.row{
	max-width: unset;
	
	.columns{
		padding-left: 0 !important;
	}

	.columns:last-child:not(:first-child){
		float: left !important;
	}
}
</style>
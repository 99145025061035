<template>
    <div style="position: absolute; overflow: hidden; left: -0px; right: -0px; top: -0px; bottom: -0px;">
        <div style="height: 100vh; position: relative; z-index: 2;">
            <GlobSpinner :isLoading="isLoading"></GlobSpinner>
            <div class="center-content">
                <div class="login-form">
                    <div class="section-1">
                        <div>
                            <img :src="logoWithText" />
                        </div>
                    </div>
                    <div class="section-2">
                        <div>
                            <p class="title">Welcome back!</p>
                            <p class="jfyi">Please enter you details</p>

                            <form @submit.prevent="login" accept-charset="utf-8">
                                <div class="form">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" spellcheck="false" class="form-control text"
                                            placeholder="Ange epost" id="validateUsername" name="validateUsername"
                                            v-model="userData.username" />
                                    </div>
                                    <div class="form-group">
                                        <label for="pwd">Password</label>
                                        <input type="password" class="form-control text" placeholder="Ange lösenord"
                                            id="validateUserPassword" name="validateUserPassword"
                                            @input="passwordError = false" v-model="userData.password" />
                                    </div>
                                    <p class="invalid-cred-error" v-if="passwordError">Sorry invalid credentials</p>
                                </div>
                                <div class=" form-check mt-3">
                                    <label class="mr-3 d-flex remember-me">
                                        <InputCheckbox class="mr-2" :name="'alert-by-phone'" v-model="remember" />
                                        Remember me
                                    </label>
                                </div>
                                <button type="submit" class="button">Login Now!</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="background" :style="`background-size: cover;background-image: url(${bg}); `
            "></div>
    </div>
</template>

<script>
import bg from "@/assets/login-bg.png";
import logoWithText from "@/assets/logo-with-text.png";
import Cookies from "js-cookie";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useApi } from "@/plugins/api";
import { useNotifications } from "@/plugins/notifications";
import $ from "jquery";
export default {
    setup() {
        const api = useApi();
        const notifications = useNotifications();
        const router = useRouter();

        const remember = ref(false);
        const passwordError = ref(false);
        const isLoading = ref(false);
        const SessionKey = ref("");

        const userData = ref({
            username: "",
            password: ""
        });

        const getCredentials = () => {
            const user = Cookies.get("ORDINGESTFrontendSessionKey");
            if (user) {
                userData.value.username = user;
                remember.value = true;
            }
        };

        const login = () => {
            if (remember.value) {
                Cookies.set("ORDINGESTFrontendSessionKey", userData.value.username, { expires: 365 });
            }
            isLoading.value = true;
            api.login(
                {
                    validateUsername: userData.value.username,
                    validateUserPassword: userData.value.password,
                    SessionKey: Cookies.get("ORDINGESTFrontendSessionKey")
                }
            )
                .then((res) => {
                    Cookies.set("ORDINGESTFrontendSessionKey", res.SessionKey);
                    notifications.push({
                        title: 'Logged it',
                        msg: 'Logged in successfully'
                    }, 'success', false, false)
                    router.push('/');
                    return true;
                })
                .catch((err) => {
                    userData.value.password = "";
                    passwordError.value = true;
                    isLoading.value = false;
                });
        };

        onMounted(() => {
            getCredentials();
        });

        return {
            passwordError,
            userData,
            remember,
            login,
            isLoading,
            bg,
            logoWithText
        };
    }
};
</script>

<style lang="scss" scoped>

.background{
	left: -20px;
	right: -20px; 
	top: -20px; 
	bottom: -20px; 
	transition: all 1s ease-in-out; 
	position: absolute;
	background-repeat: no-repeat; /* Prevent the image from repeating */
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.login-form {
  width: 966px;
  height: 630px;
  color: $white;
  background: $white;
  box-shadow: 0px 4px 35px 0px #A5C2DD4D;
  display: inline-flex;
  border-radius: 30px;

.section-1{
    width: 50%;
	position: relative;
	div{
        background: linear-gradient(332.37deg, #41A4FF -0.06%, #59CFF4 110.65%);
        width: 454px;
        height: 594px;
        border-radius: 10px;
        position: absolute;
        /* top: 1%; */
        top: 16px;
        left: 15px;
    }

    img{
        height: 140px;
        width: 140px;
        position: absolute;
        top: 35%;
        left: 33%;
    }
}
p{
	margin: 0;
}

.section-2{
	width: 50%;
	position: relative;


		.form{
			display: inline-grid;
			row-gap: 15px;
		.form-group{
			display: inline-grid;
			row-gap: 7px;
		}
	}

	input.text{
		width: 305px;
		height: 50px;
		top: 450px;
		left: 918px;
		border-radius: 6px;
        border: 1px solid #DAE8FC;
        background: linear-gradient(0deg, #DAE8FC, #DAE8FC),
linear-gradient(0deg, $white, $white);
		padding-left: 19px;
	}
	

	input:focus{
		outline: unset;
		border: 1px solid $greenishGray100
	}

	.form-check-input{
		width: 18px;
		height: 18px;
		border-radius: 6px;
		background: linear-gradient(0deg, rgba(95, 169, 238, 0.2), rgba(95, 169, 238, 0.2)),
		linear-gradient(0deg, rgba(95, 169, 238, 0.05), rgba(95, 169, 238, 0.05));
		border: 1px solid $lightBlue33;

	}

	button{
		margin-top: 1rem;
		width: 305px;
		height: 55px;
		border-radius: 6px;
		background: $primary-btn-color;
        color: $white;
		font-size: 20px;
	}

}

.section-2 > div {
	position: absolute;
    top: 16%;
    left: 22%;
}

.title{
	margin: 0;
    color: $light-text-color;
    font-size: 25px;
    font-weight: 600;
}

.jfyi{
	color: $dark-blue-shade;
	font-size: 18px;
	margin-top: 2px;
	margin-bottom: 21px;
    font-weight: 400;
}

label{
    font-size: 16px;
    font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	color: #001940CC;

}

.checkBoxs{
	width: 18px;
	height: 18px;
    border: 1px solid #DAE8FC;
}

	.checkBoxs:checked {
		background: $primary-btn-color;
		border: 1px solid $primary-btn-color !important;
	}

	.checkBoxs:checked:after {
		font-size: 13px;
		color: $lightGray;
	}
}

p.invalid-cred-error{
    margin: 0;
    color : $flash-danger;
    font-size: 16px;
}

label.remember-me{
    font-size: 16px;;
    font-weight: 400;;
    margin-top: 16px;
    margin-bottom: 4px;
}
</style>

<template>
    <div class="navbar">
        <img src="@/assets/logo.png" class="logo"/>
        <ul class="menu-list">
            <li v-for="(menuItem, index) in menuItemList" :key="'menu-item-' + index" :class="currentRouteName == menuItem.name ? 'active': ''" @click="$router.push({name: menuItem.name })">{{ $t(menuItem.translation) }}</li>
        </ul>

        <User />
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import menuItems from '@/router/menu-items.js';
import User  from './User.vue';

export default {
    components: { User },
    setup() {
        const menuItemList = ref(menuItems); 
        const router = useRouter();
        const currentRouteName = computed(() => router.currentRoute.value.name);

        return {
            menuItemList,
            currentRouteName
        }
    }

}

</script>

<style lang="scss" scoped>
 .navbar{
    height: 70px;
    align-items: center;
    display: flex;
    padding-left: 16px;
    img.logo{
        height: 50px;
        width: 50px
    }

    .menu-list{
        list-style: none;
        display: flex;
        column-gap: 6px;

        li{
            color: #414950;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            height: 36px;
            display: flex;
            align-items: center;
            padding: 16px;
        }

        li.active{
            background-color: #F2F7FE;
            border-radius: 10px;
            color: #0066FF;
        }
    }
 }
</style>

<!-- F2F3F6 -->

<template>
    <GlobRow class="mt-4">
        <GlobCol :medium="8">
            <label>{{$t('integrations.endpoint')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.endpoint')"
                    v-model="config.endpoint"
                />
            </label>
        </GlobCol>
        <GlobCol :medium="6"></GlobCol>
    </GlobRow>
    <GlobRow class="mt-4">
        <GlobCol :medium="4">
            <label>{{$t('integrations.secret')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.secret')"
                    v-model="config.secret"
                />
            </label>
        </GlobCol>
    </GlobRow>
    <GlobRow class="mt-4" v-if="isLoaded">
        <InputToggle class="mt-2" :label="$t('integrations.send_interpret_reponse')"  v-model="config.send_interpret_reponse"/>
    </GlobRow>
</template>

<script>
import { reactive, watch, onMounted, ref, onUnmounted } from 'vue';

export default {
    props: ['values'],
   setup(props, {emit}) {

        const config = reactive({
            'endpoint': '',
            'secret': '',
            'send_interpret_reponse': '',
        })

        const isLoaded = ref(false)

        onMounted(() => {
            Object.assign(config, props.values)
            isLoaded.value = true;
        })

        onUnmounted(() => {
            Object.assign(config,{})
            emit('resetConfig')
        })


        const checkValidation = () => {
            if(!config.endpoint 
                || !config.secret)
            {
                emit('requiredFieldsFilled', false)
            }else{
                emit('requiredFieldsFilled', true)
            }
            emit('config', config)
        }

        watch(() => config.endpoint, (newVal, oldVal) => {
            checkValidation()
        });

        watch(() => config.secret, (newVal, oldVal) => {
            checkValidation()
        });
        
        return {
            config,
            isLoaded
        }

   }

}
</script>
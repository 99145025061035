import Cookies from "js-cookie";
import { php } from './../endpoints.js';

const auth = {
    authenticate: async () => {
        return php.get("/currentuser").then((res) => res.data);
    },
    async login(data) {
        return new Promise((resolve, reject) => {
          php
            .post("login", data)
            .then((res) => {
              if (res.data.success == 1) {
                Cookies.set("ORDINGESTFrontendSessionKey", res.data.SessionKey)
                resolve(res.data);
              } else {
                reject(res.data);
              }
            })
            .catch((_) => {
            });
        });
    },
    async logout() {
      return php.post("logout").then((res) => {
        Cookies.remove("ORDINGESTFrontendSessionKey");
        return res;
      });
    }
}

export default auth;
import { php } from './../endpoints.js';

const messages = {
    get() {
        return php.get("/message/inbox").then((res) => res.data);
    },
    getSent() {
        return php.get("/message/sent").then((res) => res.data);
    },
    async send(data) {
        return php.post("/message", data).then((res) => res.data);
    },
    async delete(id) {
        return php.delete(`/message/${id}`).then((res) => res.data);
    },
    async updateMessage(id, data) {
        return php.put(`/message/${id}`).then((res) => res.data);
    },
}

export default messages;
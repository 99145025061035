<template>
 <div>
		<div class="p-relative">
            <h2 class="page-title">{{ $t('user.users') }}</h2>
            <label class="create-new-item" @click="showCreateModal = true">{{ $t('user.create_new') }}<font-awesome-icon icon="fa-regular fa-square-plus" /></label>
        </div>
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobXpanel>
					<inputDynamic class="table-search-input" type="text" v-model="searchString" :placeholder="$t('general.search')" />
					<GlobTable
						:maxHeight="50"
						:data="{
							head : mainListHead,
							body : filteredItems
						}"
						:actions="actions"
						:itemRenderFunctions="renderFunctions"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

<!-- Create user modal -->
<GlobModal :width="'1000px'" :title="$t('user.create')" @close="showCreateModal = false" :show="showCreateModal" :isCRUD="true" v-if="showCreateModal">
	<Create 
		:roles="roles"
		@created="(user) => loadMainList()"
		@close="showCreateModal = false" />
</GlobModal>
<!-- Edit user modal -->
<GlobModal :width="'1100px'" 
	:title="$t('user.edit_user')+': '+(selectedItem.Name ? selectedItem.Name : '')" 
	@close="selectedItem = null" :show="selectedItem && selectedItem.Id && !hideEditModal" v-if="selectedItem && selectedItem.Id" :isCRUD="true">
	<Edit 
		:roles="roles"
		:itemId="selectedItem.Id" 
		@close="selectedItem = null"
		@updated="(user) => updated(user)"
		@showDeleteConfirmModal="showDeleteConfirmModal = true"
		@showPlanDeleteConfirmModal="(id) => selectedMaintenancePlanId = id"
		@showAssetServiceReasonDeleteModal = "(id) => selectedAssetServiceRelationId = id"
		@showAssetSurveillanceDeleteModal = "showAssetSurveillanceDeleteModal = true"
			
		/>
</GlobModal>
	<!-- Delete confirm modal -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=false"  
		@delete = "deleteItem()"
		:title="$t('user.delete_user')" 
		:content="$t('user.delete_confirm')"  />

</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";
import Create from "@/views/modules/users/Create.vue";
import Edit from "@/views/modules/users/Edit.vue";

export default {
components: {
	Create,
	Edit
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const endpoint = computed(() => api.users);
    
	const roles = ref([]);
	const selectedItem = ref(null);

	const showCreateModal = ref(false);
	const showDeleteConfirmModal = ref(false)

	const hideEditModal = computed(() =>{
		return showDeleteConfirmModal.value ? true : false;
	})

	const isLoading = ref(false);
	const notifications = useNotifications();
	const searchString = ref('');
	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "Name",
				Value: computed(() => tm('user')['fields']['name'])
			},
			{
				Alias: "LastName",
				Value: computed(() => tm('user')['fields']['last_name'])
			},
			{
				Alias: "Email",
				Value: computed(() => tm('user')['fields']['email'])
			},
			{
				Alias: "AccountActive",
				Value: computed(() => tm('user')['fields']['active'])
			},

		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			type: 'primary',
				action: (obj) => {
					selectedItem.value = obj;
				}
		}
		];
	});


	const renderFunctions = computed(() => {
            return {
                AccountActive(obj) {
                    if(obj.AccountActive){
                        return '<span class="check-icon"><i class="fa-solid fa-check"></i></span>';
                    }
                    return '<span class="cross-icon"><i class="fa-solid fa-xmark"></i></span>';
                }
            }
        })
	
	 onMounted(async() => {
          loadMainList();
	 });

	const loadMainList = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
		   roles.value = res.roles;
           mainList.value = res.data;
		   isLoading.value = false;
		})
	}

	const filteredItems = computed(() => {
		return searchString.value.length > 0 ? mainList.value
				.filter(
					i => (i.Name && i.Name.toLowerCase().match(searchString.value.toLowerCase()))
					) : mainList.value;
	})


	const created = (user) => {
		showCreateModal.value = false;  
		selectedItem.value = user;
		loadMainList();
	}

	const updated = (user) => {
		let index = mainList.value.findIndex((i) => i.Id == user.Id)
		mainList.value[index] = user;
		selectedItem.value = null;
	}

	const deleteItem = () => {
		showDeleteConfirmModal.value = false;
		isLoading.value = true;
		endpoint.value.delete(selectedItem.value.Id).then((res) => {
			notifications.push(
					{
						msg: tm('user')['delete_success']
					},
					"success",
					false
			);
			selectedItem.value = null;
			loadMainList();
		});
	}


    return {
		loadMainList,
		filteredItems,
		endpoint,
		mainList,
		mainListHead,
		searchString,
		actions,
		renderFunctions,
		roles,
		isLoading,
		selectedItem,
		hideEditModal,
		showCreateModal,
		created,
		//delete
		showDeleteConfirmModal,
		deleteItem,
		updated
    };
  }
};
</script>
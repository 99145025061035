<template>
 <div>
	<div class="p-relative">
            <h2 class="page-title">{{ $t('api_key.api_keys') }}</h2>
            <label class="create-new-item" @click="showAddOrEditModal = true">{{ $t('api_key.create_new') }}<font-awesome-icon icon="fa-regular fa-square-plus" /></label>
        </div>
		<GlobRow>
			<GlobSpinner :isLoading="isLoading"/>
			<GlobCol :medium="12">
				<GlobXpanel>
					<GlobTable
						:data="{
							head : mainListHead,
							body : filteredItems
						}"
						:actions="actions"
					/>
				</GlobXpanel>
			</GlobCol>
		</GlobRow>
</div>

	<!-- Add or update key start -->

	<GlobModal 	:width="'500px'" 
				:title="selectedItem && selectedItem.Id ? $t('api_key.edit')+': '+selectedItem.Name : $t('api_key.create')"
				@close="showAddOrEditModal = false" 
				:show="showAddOrEditModal && !showDeleteConfirmModal"
				 v-if="showAddOrEditModal">
		<AddOrUpdate 
			:selectedItem="selectedItem"
			@showDeleteConfirmModal="showDeleteConfirmModal = true"
			@saved="loadMainList(), showAddOrEditModal = false, selectedItem = null"
			@close="showAddOrEditModal = false, selectedItem = null"
		 />
	</GlobModal>
	<!-- Add or update key end -->

	<!-- Delete confirm modal -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=false"  
		@delete = "deleteItem()"
		:title="$t('api_key.delete')" 
		:content="$t('api_key.delete_confirm')"  />
</template>

<script>
import { onMounted, computed, ref, reactive } from "vue";
import { useApi } from "@/plugins/api";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

import AddOrUpdate from "./AddOrUpdate.vue";

export default {
components: {
	AddOrUpdate
},
 setup() {
    const { tm } = useI18n();
	const api = useApi();
	const router = useRouter();
	const endpoint = computed(() => api.apiKeys);
	
    const showAddOrEditModal = ref(false);
	const showDeleteConfirmModal = ref(false)
	const isLoading = ref(false);
	const notifications = useNotifications();
	const searchString = ref('');
	const selectedItem = ref(null);

	const mainList  = ref([]);
	const mainListHead = ref([
			{
				Alias: "Name",
				Value: computed(() => tm('api_key')['fields']['name'])
			},
			{
				Alias: "ApiToken",
				Value: computed(() => tm('api_key')['fields']['key'])
			},

		]);
	
	const actions = computed(() => { return [
		{
			name: tm('general')['button']['edit'],
			type: "primary",
				action: (obj) => {
					selectedItem.value = obj;
					showAddOrEditModal.value = true;
				}
		},
		{
			name: tm('api_key')['copy_key'],
			type: "secondary",
				action: (obj) => {
					navigator.clipboard.writeText(obj.ApiToken);
					notifications.push(
						{
							msg: tm('api_key')['copy_key_success']
						},
						"success",
						false
					);
				}
		}
		];
	});
	
	 onMounted(async() => {
          loadMainList();
	 });

	const loadMainList = async() => {
		isLoading.value = true;
		endpoint.value.get().then(function(res){
           mainList.value = res.data;
		   isLoading.value = false;
		})
	}

	const filteredItems = computed(() => {
		return searchString.value.length > 0 ? mainList.value
				.filter(
					i => (i.ApiName && i.ApiName.toLowerCase().match(searchString.value.toLowerCase()))) : mainList.value;
	})


	const deleteItem = () => {
		showDeleteConfirmModal.value = false;
		showAddOrEditModal.value = false;
		isLoading.value = true;
		endpoint.value.delete(selectedItem.value.Id).then((res) => {
			selectedItem.value = null;
			notifications.push(
					{
						msg: tm('api_key')['delete_success']
					},
					"success",
					false
			);
			loadMainList();
		});
	}

    return {
		loadMainList,
		filteredItems,
		mainList,
		mainListHead,
		searchString,
		actions,
		isLoading,
		showAddOrEditModal,
		selectedItem,
		//delete
		showDeleteConfirmModal,
		deleteItem,
    };
  }
};
</script>

import { createApp } from "vue";
import App from "@/App.vue";
import '@/scss/main.scss';
import { registerComponents } from '@/services/core';
import { RecycleScroller } from '@/plugins/virtual-scroller'

import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import '@fortawesome/fontawesome-free/css/solid.min.css'
import '@fortawesome/fontawesome-free/css/brands.min.css'

import '/src/plugins/font-awesome/index.js';

//Create app
const app = createApp(App);

/* Global components */
const globals = require.context('@/components/globals', false, /glob-[\w-]+\.vue$/);
const inputs = require.context('@/components/inputs', false, /input-[\w-]+\.vue$/);
registerComponents(app, [
	globals,
	inputs
]);

app.component('RecycleScroller', RecycleScroller)

//Plugins
import router from "./router";
import store from "./store";

router.beforeEach(async (to, from, next) => {
	let authStatus = store.state.AUTHENTICATED;
	console.log('authStatus', authStatus)
	console.log('path', from.path)
  	switch (authStatus) {
		case true:
			if (to.path.includes('/login')) {
				next(`/login/?redirect=${to.path}`);
			}
			else{
				next();
			}
			break;
		default:
				try {
				await store.dispatch('AUTHENTICATE_USER');
				next({path:to.fullPath, replace:true});
			} catch (error) {
				if (to.path.includes('/login')) {
					next();
				}else{
					next({ path: '/login', replace:true });
				}
			}
			break
  	}
});

app.use(store);
app.use(router);

//i18n setup start
import { i18n } from '@/plugins/i18n/lang.js'
app.use(i18n)
//i18n setup stop

import JsonViewer from 'vue-json-viewer'
app.use(JsonViewer)


/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
app.component('font-awesome-icon', FontAwesomeIcon);
import '/src/plugins/font-awesome/index.js';

app.mount("#app");


<template>
    <div role="main">
        <div id="app" class="oring-main-view">
            <router-view v-slot="{ Component }">
                <component :is="Component" />
            </router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainView',
    components: {
    },
    setup() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>

.oring-main-view{
    min-height: 90vh;
    background-color: #F2F6FC;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 1rem;
}
</style>
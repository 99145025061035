<template>
	<div class="container body">
		<div class="main_container" id="app">
			<div>
				<Navbar v-if="auth"/>
				<MainView />
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Navbar from '@/views/templates/Navbar.vue';
import MainView from '@/views/templates/MainView.vue';
import { provideApi } from '@/plugins/api';
import { provideNotifications } from '@/plugins/notifications';

export default {
	name: 'App',
	components: {
		Navbar,
		MainView
	},
	setup() {
		const store = useStore();
		const auth = computed(() => store.state.AUTHENTICATED);

		provideApi();
		provideNotifications(store);

		return {
			auth
		}
	}
}

</script>
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
	faArrowRight,
	faGear,
	faCheck,
	faTrash,
	faArrowRightFromBracket,
	faTriangleExclamation,
	faXmark
} from '@fortawesome/free-solid-svg-icons'



import {
	faBell,
	faEnvelope,
	faSquarePlus
} from '@fortawesome/free-regular-svg-icons';

/* add icons to the library */
library.add(
	// Solid
	faBell,
	faEnvelope,
	faArrowRight,
	faCheck,
	faArrowRightFromBracket,
	faTrash,
	faTriangleExclamation,
	faXmark,

	//Regular
	faGear,
	faSquarePlus
);
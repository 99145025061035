import { createStore } from 'vuex'
import { createApi } from '@/plugins/api'
const api = createApi();

const createNotificationFromBackend = (data, users) => {
	return data.map((item) => {
	  return {
		title: item.MessageSubject,
		msg: item.MessageText,
		severity: item.Severity,
		id: item.Id,
		unread: item.IsRead < 1 ? true : false,
			  time: new Date(item.MessageSent),
			  from: item.SenderAccountId,
			  to: item.ReceiverAccountId,
			  link: item.Link
	  };
	}).reverse();
  };

export default createStore({
	state: {
		AUTHENTICATED: false,
		USER: {},
		STATUSES: ['hej'],
		SHOPPING_BASKET: [],
		DIFFANALYS: [],
		DATABASE_VALUES: [],
		RESERVED_ITEMS: [],
		CURRENT_PRODPLAN: [],
		SENT_MESSAGES: [],
		NOTIFICATIONS: []
	},
	mutations: {
		SET_USER(state, user) {
			state.USER = user,
			state.AUTHENTICATED = true
			this.dispatch('FETCH_NOTIFICATIONS');
			this.dispatch('FETCH_SENT_MESSAGES');
		},
		SET_NOTIFICATIONS(state, notifications) {
			state.NOTIFICATIONS = notifications;
		},
		SET_SENT_MESSAGES(state, notifications) {
			state.SENT_MESSAGES = notifications;
		},
		READ_NOTIFICATION(state, payload) {
			const item = state.NOTIFICATIONS.find((item) => item.id == payload);
			if (item.unread) {
				state.NOTIFICATIONS[state.NOTIFICATIONS.indexOf(item)].unread = false;
				api.messages.updateMessage(payload, { IsRead: true });
			}
		  },
	},
	actions: {
		async AUTHENTICATE_USER({ commit }) {
			
			return api.authenticate().then((res) => {
				commit('SET_USER', res);
			})
				.catch((err) => {
					throw err
				})
		},
		async FETCH_NOTIFICATIONS({commit}) {

			return api.messages.get().then((res) => {

				commit("SET_NOTIFICATIONS", createNotificationFromBackend(res.data));
			})
				.catch((err) => {
					throw err
			})
		},
		async FETCH_SENT_MESSAGES({commit}) {

			return api.messages.getSent().then((res) => {
				commit("SET_SENT_MESSAGES", createNotificationFromBackend(res.data));
				
			})
				.catch((err) => {
					throw err
			})
		}
	},
	getters: {
		GET_USER(state) {
			return state.USER;
		}
	}
})

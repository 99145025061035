import { dynamicTranslation } from '@/helpers.js';
import { i18n } from '@/plugins/i18n/lang.js'

export const validateForm = (form) =>{
   let error = null;
   let translations = i18n.global.messages.value[i18n.global.locale.value];

   if(form.Name.length > 50){
      error = dynamicTranslation(translations['general']['errors']['max_length'], {
         attribute: translations['administration']['file_library']['fields']['name'],
         value: 50
      })
      return error;
   }

   if(form.LastName.length > 50){
    error = dynamicTranslation(translations['general']['errors']['max_length'], {
       attribute: translations['administration']['file_library']['fields']['last_name'],
       value: 50
    })
    return error;
    }

    if(form.Email.length > 150){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
           attribute: translations['administration']['file_library']['fields']['email'],
           value: 150
        })
        return error;
    }


    if(form.CellPhone && form.CellPhone.length > 150){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
           attribute: translations['administration']['file_library']['fields']['cell_phone'],
           value: 150
        })
        return error;
    }

    if(form.Pin && form.Pin.length > 150){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
           attribute: translations['administration']['file_library']['fields']['pin'],
           value: 150
        })
        return error;
    }

    if(form.EmployeeNumber && form.EmployeeNumber.length > 50){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
           attribute: translations['administration']['file_library']['fields']['employee_number'],
           value: 50
        })
        return error;
    }

    if(form.Password && form.Password.length > 250){
        error = dynamicTranslation(translations['general']['errors']['max_length'], {
           attribute: translations['administration']['file_library']['fields']['password'],
           value: 250
        })
        return error;
    }

    if(form.Password || form.ConfirmPassword){
        if(form.Password){
            if(!form.ConfirmPassword){
                error = translations['user']['please_confirm_password']
                return error;
            }else if(form.Password != form.ConfirmPassword){
                error = translations['user']['password_confirm_mismatch']
                return error;
            }
        }

        if(form.ConfirmPassword && !form.Password){
            error = dynamicTranslation(translations['general']['errors']['required'], {
                attribute: translations['administration']['file_library']['fields']['password'],
             })
             return error;
        }
        
    }



   return error;
}
import { php } from './../endpoints.js';

const integrations = {
    async get() {
        return php.get("/integrations").then((res) => res.data);
    },
    async getDependencies() {
        return php.get("/integrations/get-dependencies").then((res) => res.data);
    },
    async store(data) {
        return php.post("/integrations", data).then((res) => res.data);
    },
    async deleteIntegration(id) {
        return php.delete(`/integrations/${id}`).then((res) => res.data);
    },
}

export default integrations;
<template>
    <GlobRow class="mt-4">
        <GlobCol :medium="8">
            <label>{{$t('integrations.end_point')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.end_point')"
                    v-model="config.end_point"
                />
            </label>
        </GlobCol>
        <GlobCol :medium="8" class="mt-4">
            <label>{{$t('integrations.access_token')}}*
                <InputDynamic  
                    :type="'text'"
                    :placeholder="$t('integrations.access_token')"
                    v-model="config.access_token"
                />
            </label>
        </GlobCol>
    </GlobRow>
    <GlobRow class="mt-4" v-if="isLoaded">
        <InputToggle class="mt-2" :label="$t('integrations.fictive_order')"  v-model="config.fictive_order"/>
        <InputToggle class="mt-2" :label="$t('integrations.allow_adding_article')"  v-model="config.allow_adding_article"/>
        <InputToggle class="mt-2" :label="$t('integrations.send_to_erp')"  v-model="config.send_to_erp"/>
    </GlobRow>
</template>

<script>
import { reactive, watch, onMounted, ref, onUnmounted } from 'vue';

export default {
    props: ['values'],
   setup(props, {emit}) {

        const config = reactive({
            'end_point': '',
            'access_token': '',
            'fictive_order': '',
            'allow_adding_article': '',
            'send_to_erp': '',
        })

        const isLoaded = ref(false)

        onMounted(() => {
            Object.assign(config, props.values)
            isLoaded.value = true;
        })

        onUnmounted(() => {
            Object.assign(config,{})
            emit('resetConfig')
        })


        const checkValidation = () => {
            if(!config.end_point || !config.access_token)
            {
                emit('requiredFieldsFilled', false)
            }else{
                emit('requiredFieldsFilled', true)
            }
            emit('config', config)
        }

        watch(() => config.end_point, (newVal, oldVal) => {
            checkValidation()
        });

        watch(() => config.access_token, (newVal, oldVal) => {
            checkValidation()
        });

        return {
            config,
            isLoaded
        }

   }

}
</script>
<template>
    <div>
        <GlobRow>
            <GlobCol :medium="8" class="mt-4">
                <label>{{ $t('reader_setup.interpreters.gpt_model_name') }}*
                    <InputDynamic  
                        :type="'text'"
                        :placeholder="$t('reader_setup.interpreters.gpt_model_name')"
                        v-model="config.model_name"
                    />
                </label>
            </GlobCol>
            <GlobCol :medium="8" class="mt-4">
                <label>{{ $t('reader_setup.interpreters.temperature') }}*
                    <InputDynamic  
                        :type="'text'"
                        :placeholder="$t('reader_setup.interpreters.temperature')"
                        v-model="config.temperature"
                    />
                </label>
            </GlobCol>
        </GlobRow>

        <GlobRow class="mt-8">
           <GlobCol>
               <button class="button button--primary filter-trigger mr-2" :disabled="isSaveButtonDisabled" @click="save()">{{  $t('general.button.save') }}</button>
               <button class="button button--cancel" @click="$emit('close')">{{  $t('general.button.cancel') }}</button>
           </GlobCol>
       </GlobRow>
    </div>
</template>

<script>
import { reactive, computed, onMounted, ref, onUnmounted } from 'vue';

export default {
    props: ['values'],
    setup(props, {emit}) {

        const config = reactive({
            'model_name': '',
            'temperature': ''
        })

        const isLoaded = ref(false)

        onMounted(() => {
            if(props.values){
                Object.assign(config, props.values)
            }
            isLoaded.value = true;
        })

        onUnmounted(() => {
            Object.assign(config,{})
            emit('resetConfig')
        })

        const isSaveButtonDisabled = computed(() => {
             
            if(!config.model_name || !config.temperature)
            {
                return true;
            }
            return false;
        })

        const save = () => {
            emit('config', config)
            emit('close')
        }

        return {
            config,
            isLoaded,
            isSaveButtonDisabled,
            save
        }

   }

}
</script>
<template>
    <input class="checkBoxs" ref="checkBox" :checked="modelValue" :id="id" type="checkbox" :disabled="disabled"
        @change="$emit('update:modelValue', $event.target.checked); $emit('input', $event.target.checked)" />
</template>

<script>
import { props } from './common';
export default {
    props: props,
    setup(props, { emit }) {
        const updateValue = (e) => {
            emit('input', { val: e.target.checked, srcEvent: e })
        }

        return {
            updateValue,
            ...props
        }
    }
}
</script>

<style lang="scss" scoped>
.checkBoxs {
    -webkit-appearance: none;
    background-color: $white;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 2px solid #9E9E9E;


    &:checked {
        color: $white;
        background-color: #11B5B5;
        border: 2px solid #11B5B5;
    }

    &:checked:after {
        font-family: "Font Awesome 6 Free";
        content: "\f00c";
        font-size: 13px;
        position: absolute;
        top: 1px;
        left: 2.4px;
        color: #FFFFFF;
        font-weight: bold;
    }
}
</style>